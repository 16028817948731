import { TinyColor } from "@ctrl/tinycolor";
import {
  ColorArea,
  ColorPicker,
  ColorPickerProps,
  ColorSlider,
} from "@fluentui/react-color-picker-preview";
import {
  Button,
  type SpinButtonOnChangeData,
  useId,
} from "@fluentui/react-components";
import { SquareFilled } from "@fluentui/react-icons";
import { useAppTheme } from "hooks/useAppTheme";
import { useAtom } from "jotai";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { selectedFullThemeAtom } from "store";
import styled from "styled-components";
import { generateTheme } from "../themeGenerator";
import { InputHexField } from "./InputFieldHex";
import { InputRgbField } from "./InputRgbField";

const HEX_COLOR_REGEX = /^#?([0-9A-Fa-f]{0,6})$/;
const NUMBER_REGEX = /^\d+$/;

type RgbKey = "r" | "g" | "b";

export const ColorPickerFluent = () => {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const hexId = useId("hex-input");

  const DEFAULT_COLOR_HSV = new TinyColor(theme.palette.themePrimary).toHsv();

  const [selectedFullTheme, setSelectedFullTheme] = useAtom(
    selectedFullThemeAtom
  );

  const [selectedColor, setSelectedColor] = React.useState(DEFAULT_COLOR_HSV);
  const [hex, setHex] = React.useState(
    new TinyColor(selectedColor).toHexString()
  );
  const [rgb, setRgb] = React.useState(new TinyColor(selectedColor).toRgb());
  const [namedColor, setNamedColor] = React.useState("");

  const handleChange: ColorPickerProps["onColorChange"] = (_, data) => {
    setSelectedColor({ ...data.color, a: data.color.a ?? 1 });
    setHex(new TinyColor(data.color).toHexString());
    setRgb(new TinyColor(data.color).toRgb());
    const _namedColor = new TinyColor(`hsl(${data.color.h},100%,50%)`).toName();
    if (_namedColor) {
      setNamedColor(_namedColor);
    }
  };

  const onRgbChange = (data: SpinButtonOnChangeData, name: string) => {
    const item = data.value || data?.displayValue;
    const value = item ? parseFloat(item?.toString()) : null;

    if (
      value === null ||
      Number.isNaN(value) ||
      !NUMBER_REGEX.test(value.toString())
    ) {
      return;
    }

    const colorKey = name as RgbKey;
    const newColor = new TinyColor({ ...rgb, [colorKey]: value });
    if (newColor.isValid) {
      setSelectedColor(newColor.toHsv());
      setHex(newColor.toHex());
      setRgb(newColor.toRgb());
    }
  };

  const colorAriaAttributes = {
    "aria-label": "ColorPicker",
    "aria-roledescription": "2D slider",
    "aria-valuetext": `Saturation ${selectedColor.s * 100}, Brightness: ${
      selectedColor.v * 100
    }, ${namedColor}`,
  };

  const handleChangePrimaryColor = (color) => {
    const { lightTheme, darkTheme } = generateTheme(color);

    setSelectedFullTheme({
      light: lightTheme,
      dark: darkTheme,
    });
  };
  const hexSelectedColor = new TinyColor(selectedColor).toHexString();

  React.useEffect(() => {
    const DEFAULT_COLOR_HSV = new TinyColor(theme.palette.themePrimary).toHsv();
    setSelectedColor(DEFAULT_COLOR_HSV);
  }, [selectedFullTheme, theme.palette.themePrimary]);

  return (
    <Container>
      <ColorPicker color={selectedColor} onColorChange={handleChange}>
        <ColorArea inputX={colorAriaAttributes} inputY={colorAriaAttributes} />
        <ColorSlider
          aria-label="Hue"
          aria-valuetext={`${selectedColor.h}°, ${namedColor}`}
        />
      </ColorPicker>
      <InputsWrap>
        <InputHexField
          id={hexId}
          value={hex}
          onChange={(e) => {
            const value = e.target.value;
            const newColor = new TinyColor(value);
            if (newColor.isValid) {
              setSelectedColor(newColor.toHsv());
            }
            setHex((oldValue) =>
              HEX_COLOR_REGEX.test(value) ? value : oldValue
            );
          }}
        />

        <InputRgbField
          label={t("hbh.red.label")}
          value={rgb.r}
          name="r"
          onChange={(_, data: SpinButtonOnChangeData) => onRgbChange(data, "r")}
        />
        <InputRgbField
          label={t("hbh.green.label")}
          value={rgb.g}
          name="g"
          onChange={(_, data: SpinButtonOnChangeData) => onRgbChange(data, "g")}
        />
        <InputRgbField
          label={t("hbh.blue.label")}
          value={rgb.b}
          name="b"
          onChange={(_, data: SpinButtonOnChangeData) => onRgbChange(data, "b")}
        />
      </InputsWrap>
      <Wrap
        selectedColor={
          hexSelectedColor === theme.palette.themePrimary
            ? hexSelectedColor
            : ""
        }
      >
        <SquareFilled
          style={{
            color: new TinyColor(selectedColor).toHexString(),
            width: "45px",
            height: "45px",
          }}
          className="icon"
        />
      </Wrap>
      <Button
        onClick={() => {
          if (hexSelectedColor !== theme.palette.themePrimary) {
            handleChangePrimaryColor(hexSelectedColor);
          }
        }}
      >
        {t("hbh.generate.label")}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const InputsWrap = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  gap: 5px;
`;

const Wrap = styled.div<{ selectedColor?: string }>`
  width: fit-content;
  border: ${({ theme, selectedColor }) =>
    selectedColor
      ? `2px solid ${selectedColor}`
      : `2px solid ${theme.palette.neutralLight}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.palette.white};
  border-radius: 4px;

  @keyframes shine {
    from {
      -webkit-mask-position: 150%;
    }

    to {
      -webkit-mask-position: -50%;
    }
  }

  .icon {
    -webkit-mask-image: linear-gradient(
      -75deg,
      rgba(0, 0, 0, 0.6) 30%,
      #000 50%,
      rgba(0, 0, 0, 0.6) 70%
    );
    -webkit-mask-size: 200%;
    animation: shine 2s infinite;
  }
`;
