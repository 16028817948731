import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IDependent } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Props = {
  countryCode: number;
  clientInternalNumberGos: number;
  employeeDependentTag: number;
  enabled?: boolean;
};

const getDependentData = async ({
  countryCode,
  clientInternalNumberGos,
  employeeDependentTag,
}: Props) => {
  const response = await api.get(
    `/employee/dependent/${countryCode}/${clientInternalNumberGos}/${employeeDependentTag}`
  );
  return response.data;
};

export function useGetDependentData({
  enabled,
  countryCode,
  employeeDependentTag,
  clientInternalNumberGos,
}: Props): UseQueryResult<IDependent> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IDependent, Error>(
    [
      QUERY_KEYS.dependentData,
      countryCode,
      clientInternalNumberGos,
      employeeDependentTag,
    ],
    () => {
      return getDependentData({
        countryCode,
        clientInternalNumberGos,
        employeeDependentTag,
      });
    },
    {
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}

export const useInvalidateDependentData = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.dependentData,
    });
};
