import { Button, Dialog, DialogSurface } from "@fluentui/react-components";
import { GiftOpenRegular, RocketRegular } from "@fluentui/react-icons";
import { CancelIcon } from "@fluentui/react-icons-mdl2";
import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";
import DatePickerField from "components/controls/DatePickerField";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex } from "components/styled";
import { devices } from "library/constants";
import { ICardBenefitPackage } from "library/types";
import { formatDateFieldForPayload } from "library/utils";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useEnrollPackage } from "state/useEnrollPackage";
import { getEnrollmentCancellationPossibilityDate } from "state/useGetEnrollmentCancellationPossibilityDate";
import { useUpgradeEnrollment } from "state/useUpgradeEnrollment";
import styled from "styled-components";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  benefitPackage: ICardBenefitPackage;
  isUpgradeEnroll?: boolean;
};

export function EnrollPackageDialog({
  open,
  setOpen,
  benefitPackage,
  isUpgradeEnroll,
}: Props) {
  const { t } = useTranslation();

  const { mutate: onEnrollPackage, isLoading: enrollInProgress } =
    useEnrollPackage();
  const { mutate: onUpgradePackage, isLoading: upgradeEnrollInProgress } =
    useUpgradeEnrollment();
  const [enrollmentDate, setEnrollmentDate] = useState<Date | null>(new Date());

  const [cancellationPossibility, setCancellationPossibility] =
    useState<Date | null>(null);

  const handleEnrollPackage = useCallback(
    (benefitPackage: ICardBenefitPackage, enrollmentDate: Date) => {
      const mutationFn = isUpgradeEnroll ? onUpgradePackage : onEnrollPackage;
      mutationFn(
        {
          countryCode: Number(benefitPackage.countryCode),
          clientInternalNumberGOS: Number(
            benefitPackage.clientInternalNumberGos
          ),
          benefitPackageTag: Number(benefitPackage.benefitPackageTag),
          employeeDependentTag:
            Number(benefitPackage.employeeDependentTag) || 0,
          employeeEnrollmentTag: Number(benefitPackage.employeeEnrollmentTag),
          dateOfEnrollment: formatDateFieldForPayload(enrollmentDate) || "",
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.packageEnrolledSuccessfully.label"));
            setOpen(false);
          },
        }
      );
    },
    [benefitPackage, onEnrollPackage, enrollmentDate, isUpgradeEnroll]
  );

  const getCancellationPossibility = async (benefitPackage) => {
    const cancellationPossibility =
      await getEnrollmentCancellationPossibilityDate({
        countryCode: Number(benefitPackage.countryCode),
        clientInternalNumberGOS: Number(benefitPackage.clientInternalNumberGos),
        benefitPackageTag: Number(benefitPackage.benefitPackageTag),
        employeeDependentTag: benefitPackage.employeeDependentTag
          ? Number(benefitPackage.employeeDependentTag)
          : 0,
        employeeEnrollmentTag: Number(benefitPackage.employeeEnrollmentTag),
        dateOfEnrollment: formatDateFieldForPayload(enrollmentDate) || "",
      });
    setCancellationPossibility(new Date(cancellationPossibility));
  };

  useEffect(() => {
    if (!benefitPackage) return;
    if (benefitPackage.cancellationPossibility) {
      setCancellationPossibility(
        new Date(benefitPackage.cancellationPossibility)
      );
      return;
    }
    if (enrollmentDate && !benefitPackage.cancellationPossibility) {
      getCancellationPossibility(benefitPackage);
    }
  }, [enrollmentDate, benefitPackage]);

  if (!benefitPackage) {
    return <AppLogoSpinner />;
  }

  const isLoading = enrollInProgress || upgradeEnrollInProgress;

  return (
    <Dialog
      modalType="alert"
      open={open}
      onOpenChange={(event, data) => setOpen(data.open)}
    >
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {isLoading && <AppLogoSpinner />}
        <DatePickerField
          required
          minDate={new Date()}
          name="dateOfEnrollment"
          value={enrollmentDate as Date}
          setFieldValue={(name, value) => {
            setEnrollmentDate(value);
          }}
          label={t("hbh.dateOfEnrollment.label")}
          error={""}
        />
        <DatePickerField
          disabled
          name="cancellationPossibility"
          value={cancellationPossibility as Date}
          setFieldValue={() => {
            return;
          }}
          label={t("hbh.cancellationPossibility.label")}
          error={""}
        />
        <Flex width="100%" $justify="flex-end" $gap={"10px"}>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            appearance="secondary"
            icon={
              <CancelIcon
                style={{
                  fontSize: 16,
                }}
              />
            }
          >
            {t("greco.cancel")}
          </Button>
          <PrimaryButton
            disabled={!enrollmentDate || !cancellationPossibility || isLoading}
            icon={isUpgradeEnroll ? <RocketRegular /> : <GiftOpenRegular />}
            onClick={() => {
              handleEnrollPackage(benefitPackage, enrollmentDate as Date);
            }}
            style={{
              paddingLeft: "25px",
              paddingRight: "25px",
            }}
          >
            {isUpgradeEnroll ? t("hbh.upgrade.label") : t("hbh.enroll.label")}
          </PrimaryButton>
        </Flex>
      </StyledDialogSurface>
    </Dialog>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 600px;
    min-width: 600px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;
