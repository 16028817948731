import React from "react";
import { ReactComponent as Chat } from "../../assets/chatIcon.svg";
import styled from "styled-components";

type Props = {
  style?: React.CSSProperties;
};

export const ChatIcon = ({ style }: Props) => {
  return <Icon style={style} />;
};

const Icon = styled(Chat)`
  border-radius: 50%;
  padding: 1px;
  background: ${({ theme }) => theme.palette.orangeLighter};
`;
