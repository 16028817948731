import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IEmployee } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Props = {
  countryCode: number;
  clientInternalNumberGos: number;
  enabled?: boolean;
};

const getEmployeeData = async ({
  countryCode,
  clientInternalNumberGos,
}: Props) => {
  const response = await api.get(
    `/employee/${countryCode}/${clientInternalNumberGos}`
  );

  return response.data;
};

export function useGetEmployeeData({
  enabled,
  countryCode,
  clientInternalNumberGos,
}: Props): UseQueryResult<IEmployee> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IEmployee, Error>(
    [QUERY_KEYS.employeeData, countryCode, clientInternalNumberGos],
    () => {
      return getEmployeeData({ countryCode, clientInternalNumberGos });
    },
    {
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}

export const useInvalidateEmployeeData = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.employeeData,
    });
};
