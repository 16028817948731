export const ROUTES = {
  HOME: "/",
  PROFILE: "/profile",
  BENEFITS: "/benefits",
  OFFERINGS: "/offerings",
  UNAUTHORIZED_PAGE: "/unauthorized",
};

export const breakpoints = {
  xs: 320,
  xs2: 370,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export const devices = {
  xs: `(min-width: ${breakpoints.xs}px)`,
  xs2: `(min-width: ${breakpoints.xs2}px)`,
  sm: `(min-width: ${breakpoints.sm}px)`,
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  "2xl": `(min-width: ${breakpoints["2xl"]}px)`,
};

export const sizes = {
  appHeader: 50,
  appNavHeightMobile: 60,
  appNavHeightDesktop: 40,
};
