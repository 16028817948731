import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IEmployeeEnrollment } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Props = {
  countryCode: number;
  clientInternalNumberGos: number;
  enabled?: boolean;
};

const getEmployeeEnrollments = async ({
  countryCode,
  clientInternalNumberGos,
}: Props) => {
  const response = await api.get(
    `/employeeenrollment/${countryCode}/${clientInternalNumberGos}`
  );

  return response.data;
};

export function useGetEmployeeEnrollments({
  enabled,
  countryCode,
  clientInternalNumberGos,
}: Props): UseQueryResult<IEmployeeEnrollment[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IEmployeeEnrollment[], Error>(
    [QUERY_KEYS.employeeEnrollments, countryCode, clientInternalNumberGos],
    () => {
      return getEmployeeEnrollments({
        countryCode,
        clientInternalNumberGos,
      });
    },
    {
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}

export const useInvalidateEmployeeEnrollments = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.employeeEnrollments,
    });
};
