import {
  Button,
  Dialog,
  DialogSurface,
  makeStyles,
} from "@fluentui/react-components";
import { DismissRegular, GiftOpenRegular } from "@fluentui/react-icons";
import { CancelIcon } from "@fluentui/react-icons-mdl2";
import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";
import DatePickerField from "components/controls/DatePickerField";
import { TextareaField } from "components/controls/TextareaField";
import { Flex, RemoveButton } from "components/styled";
import { devices } from "library/constants";
import { ICardBenefitPackage } from "library/types";
import { formatDateFieldForPayload } from "library/utils";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCancelEnrolment } from "state/useCancelEnrollment";
import styled from "styled-components";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  benefitPackage: ICardBenefitPackage;
};

export function CancelEnrolledPackage({
  open,
  setOpen,
  benefitPackage,
}: Props) {
  const { t } = useTranslation();

  const { mutate: onCancelPackage, isLoading: cancelInProgress } =
    useCancelEnrolment();
  const [dateOfCancellation, setDateOfCancellation] = useState<Date | null>(
    new Date()
  );

  const [cancellationReason, setCancellationReason] = useState("");

  const [cancellationPossibility, setCancellationPossibility] =
    useState<Date | null>(null);

  const handleCancelEnrolledPackage = useCallback(
    (
      benefitPackage: ICardBenefitPackage,
      dateOfCancellation: Date,
      cancellationReason
    ) => {
      onCancelPackage(
        {
          countryCode: Number(benefitPackage.countryCode),
          clientInternalNumberGOS: Number(
            benefitPackage.clientInternalNumberGos
          ),
          benefitPackageTag: Number(benefitPackage.benefitPackageTag),
          employeeDependentTag:
            Number(benefitPackage.employeeDependentTag) || 0,
          employeeEnrollmentTag: Number(benefitPackage.employeeEnrollmentTag),
          dateOfCancellation:
            formatDateFieldForPayload(dateOfCancellation) || "",
          payload: {
            reasonForCancellation: cancellationReason,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.packageCanceledSuccessfully.label"));
            setOpen(false);
          },
        }
      );
    },
    [benefitPackage, dateOfCancellation, cancellationReason]
  );

  useEffect(() => {
    if (!benefitPackage) return;
    if (benefitPackage.cancellationPossibility) {
      setCancellationPossibility(
        new Date(benefitPackage.cancellationPossibility)
      );
      return;
    }
  }, [benefitPackage]);

  if (!benefitPackage) {
    return <AppLogoSpinner />;
  }

  return (
    <Dialog
      modalType="alert"
      open={open}
      onOpenChange={(event, data) => setOpen(data.open)}
    >
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {cancelInProgress && <AppLogoSpinner />}
        <DatePickerField
          required
          name="dateOfCancellation"
          value={dateOfCancellation as Date}
          setFieldValue={(name, value) => {
            setDateOfCancellation(value);
          }}
          minDate={new Date()}
          label={t("hbh.cancellationDate.label")}
          error={""}
        />
        <TextareaField
          label={t("hbh.cancelationReason.label")}
          name="reasonForCancellation"
          value={cancellationReason}
          setFieldValue={(name, value) => {
            setCancellationReason(value);
          }}
          error={""}
          rows={3}
          maxLength={4000}
          resize="vertical"
          required={true}
        />
        <DatePickerField
          disabled
          name="cancellationPossibility"
          value={cancellationPossibility as Date}
          setFieldValue={() => {
            return;
          }}
          label={t("hbh.cancellationPossibility.label")}
          error={""}
        />
        <Flex width="100%" $justify="flex-end" $gap={"10px"}>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            appearance="secondary"
          >
            {t("greco.cancel")}
          </Button>
          <RemoveButton
            disabled={
              !dateOfCancellation || cancelInProgress || !cancellationReason
            }
            icon={<DismissRegular />}
            onClick={() => {
              handleCancelEnrolledPackage(
                benefitPackage,
                dateOfCancellation as Date,
                cancellationReason
              );
            }}
            style={{
              paddingLeft: "25px",
              paddingRight: "25px",
            }}
          >
            {t("hbh.cancelEnroll.label")}
          </RemoveButton>
        </Flex>
      </StyledDialogSurface>
    </Dialog>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 600px;
    min-width: 600px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "1200px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
});
