import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { IEmployeeEnrollment } from "library/types";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeEnrollments } from "./useGetEnrollments";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  benefitPackageTag: number;
  employeeEnrollmentTag: number;
  employeeDependentTag?: number;
  dateOfEnrollment: string;
};

const upgradeEnrollment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitPackageTag,
  employeeEnrollmentTag,
  employeeDependentTag = 0,
  dateOfEnrollment,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/upgradeenrollment/${countryCode}/${clientInternalNumberGOS}/${benefitPackageTag}/${employeeDependentTag}/${employeeEnrollmentTag}/${dateOfEnrollment}`
  );

  return response.data as IEmployeeEnrollment;
};

export function useUpgradeEnrollment(refetch = true) {
  const invalidateEmployeeEnrollments = useInvalidateEmployeeEnrollments();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(upgradeEnrollment, {
    mutationKey: MUTATION_KEYS.upgradeEnrollment,
    onSuccess: () => {
      if (refetch) {
        invalidateEmployeeEnrollments();
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
