import React, { useEffect, useMemo } from "react";

import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
} from "@fluentui/react-components";
import Select, { components } from "react-select";

import { PeopleRegular, PersonRegular } from "@fluentui/react-icons";
import { useAppTheme } from "hooks/useAppTheme";
import useEmployee from "hooks/useEmployee";
import { useGetMsalUserEmail } from "hooks/useGetMsalUserEmail";
import { useAtom, useAtomValue } from "jotai";
import { SelectItem } from "library/types";
import { selectedPackageUserAtom } from "store/UIBenefitsPage";

import { useTranslation } from "react-i18next";
import { CustomUserOption } from "./CustomUserOption";
import { selectedClientAtom } from "store";

const GroupHeading = (props) => {
  const theme = useAppTheme();
  const groupStyles = {
    color: "white",
    background: `${theme.palette.themePrimary}`,
    borderBottom: `1px solid ${theme.palette.white}`,
    padding: "5px 0px",
    display: "flex",
  };
  return (
    <div style={groupStyles}>
      <components.GroupHeading {...props} />
    </div>
  );
};

type Props = {
  view: "mobile" | "desktop";
};

const PackageUserSelect = ({ view }) => {
  const userUniqueEmail = useGetMsalUserEmail();

  const selectedClient = useAtomValue(selectedClientAtom);
  const [selectedPackageUser, setSelectedPackageUser] = useAtom(
    selectedPackageUserAtom
  );

  const { employeeData } = useEmployee();
  const theme = useAppTheme();

  const packageUserOptions = useMemo(() => {
    const options = [] as SelectItem[];
    const employeeOptions = [] as SelectItem[];
    const dependentsOptions = [] as SelectItem[];
    if (!employeeData)
      return {
        options,
        employeeOptions,
        dependentsOptions,
      };

    employeeOptions.push({
      ...employeeData,
      value: userUniqueEmail || "",
      text: `${employeeData?.firstName} ${employeeData?.lastName}`,
      label: `${employeeData?.firstName} ${employeeData?.lastName}`,
    });
    employeeData?.dependents?.forEach((dependent) => {
      dependentsOptions.push({
        ...dependent,
        value: dependent.employeeDependentTag || "",
        text: `${dependent?.firstName} ${dependent?.lastName}`,
        label: `${dependent?.firstName} ${dependent?.lastName}`,
      });
    });
    return {
      options: [...employeeOptions, ...dependentsOptions],
      employeeOptions,
      dependentsOptions,
    };
  }, [employeeData?.firstName, selectedClient]);

  useEffect(() => {
    setSelectedPackageUser({
      ...employeeData,
      value: userUniqueEmail || "",
      text: `${employeeData?.firstName} ${employeeData?.lastName}`,
    });
  }, [employeeData]);

  const { t } = useTranslation();
  const onChange = (option) => {
    setSelectedPackageUser(option);
  };

  const employeeOption = packageUserOptions.employeeOptions;
  const dependentOptions = packageUserOptions.dependentsOptions;

  if (view === "desktop") {
    return (
      <Breadcrumb aria-label="Breadcrumb default example">
        <BreadcrumbItem>
          <BreadcrumbButton
            icon={<PersonRegular />}
            value={userUniqueEmail || ""}
            current={userUniqueEmail === selectedPackageUser?.value}
            onClick={() => {
              setSelectedPackageUser({
                ...employeeData,
                content: `${employeeData?.firstName} ${employeeData?.lastName}`,
                value: userUniqueEmail || "",
                text: `${employeeData?.firstName} ${employeeData?.lastName}`,
                label: `${employeeData?.firstName} ${employeeData?.lastName}`,
              });
            }}
          >
            {`${employeeData?.firstName} ${employeeData?.lastName}`}
          </BreadcrumbButton>
        </BreadcrumbItem>
        {packageUserOptions.dependentsOptions?.length && <BreadcrumbDivider />}
        {packageUserOptions.dependentsOptions.map((option, index) => {
          return (
            <>
              <BreadcrumbItem>
                <BreadcrumbButton
                  icon={<PeopleRegular />}
                  value={option.value}
                  current={option.value === selectedPackageUser?.value}
                  onClick={() => {
                    setSelectedPackageUser(option);
                  }}
                >
                  {option.text}
                </BreadcrumbButton>
              </BreadcrumbItem>
              {index !== packageUserOptions.dependentsOptions.length - 1 && (
                <BreadcrumbDivider />
              )}
            </>
          );
        })}
      </Breadcrumb>
    );
  }

  return (
    <Select
      inputValue={""}
      options={[
        {
          label: t("hbh.employee.label"),
          options: employeeOption,
        },
        {
          label: t("hbh.dependents.label"),
          options: dependentOptions,
        },
      ]}
      value={[...employeeOption, ...dependentOptions]?.filter(function (
        option
      ) {
        return option.value === selectedPackageUser?.value;
      })}
      components={{ GroupHeading, Option: CustomUserOption }}
      onChange={onChange}
      styles={{
        groupHeading: (base) => ({
          ...base,
          flex: "1 1",
          color: theme.palette.white,
          margin: 0,
        }),
        container: (baseStyles) => ({
          ...baseStyles,
          position: "relative",
          width: "100%",
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          background: "transparent",

          boxShadow: "none",

          border: "none",
          borderRadius: 0,
          borderBottom: `1px solid ${theme.palette.neutralLight} !important`,
        }),
        menu: (p) => ({
          ...p,
          zIndex: 15,
          background: theme.palette.neutralLighter,
        }),
        option: (p, state) => ({
          ...p,
          background: state.isFocused
            ? theme.palette.neutralLighter
            : theme.palette.white,
          borderLeft: state.isSelected
            ? `4px solid ${theme.palette.themePrimary}`
            : `4px solid ${theme.palette.white}`,
          "&:hover": {
            background: theme.palette.neutralLighter,
          },
          color: theme.palette.black,
          fontSize: "14px",
          fontWeight: 400,
        }),
        input: (p) => ({
          ...p,
          margin: "4px",
          color: (function () {
            return theme.palette.black;
          })(),
        }),

        singleValue: (p, state) => ({
          ...p,
          fontSize: "14px",
          marginLeft: "5px",
          fontWeight: 400,
          color: theme.palette.black,
        }),
        dropdownIndicator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),

        indicatorSeparator: (p, state) => ({
          ...p,
          visibility: "hidden",
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
          overflow: "visible",
        }),
      }}
    />
  );
};

export default React.memo(PackageUserSelect);
