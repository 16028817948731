import { PageContent } from "components/styled";
import { useGetMsalUserEmail } from "hooks/useGetMsalUserEmail";
import { useAtomValue } from "jotai";
import { EApprovalStatus, ETaxonomy, ICardBenefitPackage } from "library/types";
import { useMemo } from "react";
import { useGetCompanyBenefitPackageViews } from "state/useGetCompanyBenefitPackageViews";
import { useGetEmployeeEnrollments } from "state/useGetEnrollments";
import { selectedClientAtom } from "store";
import { benefitContentTabAtom } from "store/UIBenefitsPage";
import { BenefitsPageContent } from "./components/BenefitsPageContent/BenefitsPageContent";
import { BenefitsPageHeader } from "./components/BenefitsPageHeader/BenefitsPageHeader";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";

export function BenefitsPage() {
  const selectedClient = useAtomValue(selectedClientAtom);
  const selectedBenefitContentTab = useAtomValue(benefitContentTabAtom);
  const userUniqueEmail = useGetMsalUserEmail();

  const enrollmentStatusOptions = useTaxonomyOptions(ETaxonomy.ApprovalStatus);

  const { data: companyBenefitPackages } = useGetCompanyBenefitPackageViews({
    clientInternalNumberGos: Number(selectedClient?.clientInternalNumberGos),
    countryCode: Number(selectedClient?.countryCode),
    enabled: !!selectedClient,
  });

  const { data: employeeEnrollments } = useGetEmployeeEnrollments({
    clientInternalNumberGos: Number(selectedClient?.clientInternalNumberGos),
    countryCode: Number(selectedClient?.countryCode),
    enabled: !!selectedClient,
  });

  const packageData = useMemo(() => {
    const eligiblePackages = [] as ICardBenefitPackage[];
    const enrolledPackages = [] as ICardBenefitPackage[];
    if (!companyBenefitPackages || !employeeEnrollments)
      return {
        eligiblePackages,
        enrolledPackages,
      };

    companyBenefitPackages.forEach((benefit) => {
      const enrollments = employeeEnrollments?.filter(
        (item) => item.benefitPackageTag === benefit?.benefitPackageTag
      );

      enrollments.forEach((enrollment) => {
        const isDependent = enrollment?.isDependent;
        const approvalStatusCode = enrollmentStatusOptions.find(
          (status) => status.value === enrollment?.enrollmentApprovalStatus
        )?.code;

        const hasApprovedStatus =
          approvalStatusCode === EApprovalStatus.APPROVED ||
          !approvalStatusCode;
        if (enrollment.dateOfEnrollment && hasApprovedStatus) {
          enrolledPackages.push({
            ...benefit,
            enrolled: true,
            cancellationPossibility: enrollment?.cancellationPossibility || "",
            employeeEnrollmentTag: enrollment.employeeEnrollmentTag,
            dateOfCancellation: enrollment.dateOfCancellation || "",
            dateOfEnrollment: enrollment.dateOfEnrollment || "",
            paidByCompany: enrollment.paidByCompany,
            paidByEmployee: enrollment.paidByEmployee,
            dateOfEligibility: enrollment.dateOfEligibility || "",
            endDateOfEligibility: enrollment.endDateOfEligibility || "",
            price: enrollment?.price,
            enrollmentDocuments: enrollment?.attachments || [],
            isUpgradeable: enrollment?.isUpgradeable,
            requiresEnrollmentApproval: enrollment?.requiresEnrollmentApproval,
            requiresCancellationApproval:
              enrollment?.requiresCancellationApproval,
            isDependent,
            approvalStatusCode,
            ...(isDependent
              ? { employeeDependentTag: enrollment?.employeeDependentTag }
              : { employeeLoginEmailAddress: userUniqueEmail }),
          });
        } else {
          eligiblePackages.push({
            ...benefit,
            isDependent,
            employeeEnrollmentTag: enrollment.employeeEnrollmentTag,
            dateOfCancellation: enrollment.dateOfCancellation || "",
            dateOfEnrollment: enrollment.dateOfEnrollment || "",
            dateOfEligibility: enrollment.dateOfEligibility || "",
            endDateOfEligibility: enrollment.endDateOfEligibility || "",
            cancellationPossibility: enrollment?.cancellationPossibility || "",
            paidByCompany: enrollment.paidByCompany,
            paidByEmployee: enrollment.paidByEmployee,
            enrollmentDocuments: enrollment?.attachments || [],
            isUpgradeable: enrollment?.isUpgradeable,
            requiresEnrollmentApproval: enrollment?.requiresEnrollmentApproval,
            approvalStatusCode,

            requiresCancellationApproval:
              enrollment?.requiresCancellationApproval,
            price: enrollment?.price,
            ...(isDependent
              ? { employeeDependentTag: enrollment?.employeeDependentTag }
              : { employeeLoginEmailAddress: userUniqueEmail }),
          });
        }
      });
    });

    return {
      eligiblePackages,
      enrolledPackages,
    };
  }, [employeeEnrollments, companyBenefitPackages, userUniqueEmail]);

  const isLoading = !companyBenefitPackages || !employeeEnrollments;

  return (
    <>
      <PageContent>
        <BenefitsPageHeader
          enrolledData={packageData?.enrolledPackages}
          isLoading={isLoading}
        />
        <BenefitsPageContent packageData={packageData} isLoading={isLoading} />
      </PageContent>
    </>
  );
}
