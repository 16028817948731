import {
  Button,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarTitle,
  Text,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { DeleteIcon } from "@fluentui/react-icons-mdl2";
import { Flex } from "components/styled";
import { INotification } from "library/types";
import styled from "styled-components";
import { ReactComponent as NotificationLogo } from "../../../../assets/NotificationLogo.svg";
import { INotificationStrings } from "./INotificationStrings";
import { useTranslation } from "react-i18next";
export interface INotificationProps {
  strings?: INotificationStrings;
  notifications?: INotification[];
  clearNotifications?: () => void;
}

const Notification = (props: INotificationProps) => {
  const { t } = useTranslation();
  const hasMessages = !!props?.notifications?.length;

  return (
    <Container>
      {hasMessages ? (
        props.notifications?.map((message) => {
          const messageList = message?.text.split("\r\n");

          return (
            <MessageBar
              intent={message.type}
              key={message.id}
              style={{
                marginBottom: "10px",
              }}
            >
              <MessageBarBody>
                <MessageBarTitle>
                  <Text
                    block
                    weight="bold"
                  >{`${message.type.toUpperCase()}`}</Text>
                </MessageBarTitle>
                <MessageContent>
                  {messageList.map((msg) => {
                    return <Text block>{msg}</Text>;
                  })}
                  <Button
                    style={{
                      paddingLeft: "0",
                    }}
                    appearance="transparent"
                    onClick={() => {
                      navigator.clipboard.writeText(message.text);
                    }}
                  >
                    {t("hbh.copyToClipboard.label")}
                  </Button>
                </MessageContent>
              </MessageBarBody>
              <MessageBarActions
                containerAction={
                  <Button
                    onClick={() => {
                      if (message.handleClearNotification) {
                        message.handleClearNotification(message);
                      }
                    }}
                    aria-label="dismiss"
                    appearance="transparent"
                    icon={<DismissRegular />}
                  />
                }
              ></MessageBarActions>
            </MessageBar>
          );
        })
      ) : (
        <Flex
          direction="column"
          align="center"
          $justify="center"
          width={"100%"}
        >
          <Logo />
          <div>
            <b>
              {(props.strings && props.strings.noNotificationsTitle) ||
                t("hbh.noNotificationsMsg.label")}
            </b>
          </div>
          <div>
            {(props.strings && props.strings.noNotificationsSubtitle) ||
              t("hbh.checkAgainForUpdate.label")}
          </div>
        </Flex>
      )}
      {props?.clearNotifications && props.notifications?.length ? (
        <ClearAllWrap>
          <Button
            iconPosition="after"
            appearance="transparent"
            onClick={props?.clearNotifications}
            icon={<DeleteIcon />}
          >
            {t("hbh.clearAll.label")}
          </Button>
        </ClearAllWrap>
      ) : null}
    </Container>
  );
};

export default Notification;

const Container = styled.div``;

const Logo = styled(NotificationLogo)`
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 85px;
  height: 85px;
`;
const ClearAllWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  padding: 10px;
`;

const MessageContent = styled.div`
  margin-top: 5px;
`;
