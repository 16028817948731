import { AddCircleRegular } from "@fluentui/react-icons";
import { ActionButton, FormFieldsWrap } from "components/styled";
import useEmployee from "hooks/useEmployee";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { DependentItem } from "./DependentItem";

export function DependentsForm() {
  const { employeeData } = useEmployee();

  const { t } = useTranslation();
  const [addNewDependent, setAddNewDependent] = useState(false);

  const dependentList = employeeData?.dependents;

  const handleCloseAddNewDep = useCallback(() => {
    setAddNewDependent(false);
  }, []);

  return (
    <FormFieldsWrap>
      {addNewDependent ? (
        <DependentItem
          addNew
          employeeData={employeeData}
          dependentData={null}
          removeNewDependent={handleCloseAddNewDep}
        />
      ) : (
        <>
          <ActionButton
            noBorder
            appearance="transparent"
            icon={<AddCircleRegular />}
            onClick={() => {
              setAddNewDependent(true);
            }}
          >
            {t("hbh.addDependent.label")}
          </ActionButton>
        </>
      )}
      {dependentList?.map((dependent) => (
        <DependentItem
          dependentData={dependent}
          key={dependent?.employeeDependentTag}
          employeeData={employeeData}
          removeNewDependent={handleCloseAddNewDep}
        />
      ))}
    </FormFieldsWrap>
  );
}
