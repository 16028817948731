import {
  Combobox,
  ComboboxProps,
  useComboboxFilter,
  useId,
} from "@fluentui/react-components";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";
import { SelectItem } from "library/types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TInputAppearance } from "theme/types";

type Props = {
  name: string;
  label?: string;
  info?: string;
  options?: SelectItem[];
  placeholder?: string;
  value: SelectItem | null;
  setFieldValue: (name: string, value: SelectItem) => void;
  error: string;
  noErrorSection?: boolean;
  appearance?: TInputAppearance;
  noLabel?: boolean;
} & Partial<ComboboxProps>;

export const FilteringCombobox = ({
  label = "",
  options = [],
  name = "",
  info,
  value,
  placeholder = "",
  error = "",
  noErrorSection = false,
  required = false,
  noLabel = false,
  appearance = "underline",
  setFieldValue,
  ...props
}: Props) => {
  const comboId = useId();
  const { t } = useTranslation();

  const [query, setQuery] = React.useState<string>("");

  const formattedOptions = options.map((option) => ({
    children: option.text,
    value: option.text,
    custom: option,
  }));
  const children = useComboboxFilter(
    query,
    // options.map((option) => ({
    //   children: option.text,
    //   value: option.value.toString(),
    // })),
    formattedOptions,
    {
      noOptionsMessage: t("hbh.noItemsFound.label"),
    }
  );
  const onOptionSelect: ComboboxProps["onOptionSelect"] = (e, data) => {
    const selectedOption = options.find(
      (option) => option.text === data.optionText
    );
    setQuery(data.optionText ?? "");
    setFieldValue(name, selectedOption as SelectItem);
  };

  React.useEffect(() => {
    setQuery(value?.text || "");
  }, [value]);

  return (
    <Container hasvalue={!!query + ""}>
      <FormFieldContainer>
        {!noLabel && (
          <FormFieldLabel label={label} required={!!required} info={info} />
        )}
        <Combobox
          onClick={(e) => {
            e.stopPropagation();
          }}
          appearance={appearance}
          clearable
          style={{
            minWidth: "100%",
          }}
          onOptionSelect={onOptionSelect}
          aria-labelledby={comboId}
          placeholder={placeholder || t("greco.form.selectPlaceholder")}
          onChange={(ev) => setQuery(ev.target.value)}
          value={query}
          {...props}
        >
          {children}
        </Combobox>
        {!noErrorSection && (
          <ErrorMessageContainer>
            <StyledErrorMessage>{error}</StyledErrorMessage>
          </ErrorMessageContainer>
        )}
      </FormFieldContainer>
    </Container>
  );
};

const Container = styled.div<{ hasvalue: string }>`
  width: 100%;
  .fui-Combobox__clearIcon {
    display: ${({ hasvalue }) => (hasvalue === "true" ? "block" : "none")};
  }
  .fui-Combobox__expandIcon {
    display: ${({ hasvalue }) => (hasvalue === "true" ? "none" : "block")};
  }
`;
