// --- Fabric UI
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Persona,
} from "@fluentui/react-components";
import { EditRegular, SignOutRegular } from "@fluentui/react-icons";
import { ROUTES } from "library/constants";
import { IEmployee } from "library/types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logout } from "service/auth";
import styled from "styled-components";

interface IProps {
  userData: IEmployee | null;
}

export const UserSettings = ({ userData }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const gotoUserProfilePage = useCallback(() => {
    navigate(ROUTES.PROFILE);
  }, []);

  return (
    <>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <MenuButton
            style={{
              minWidth: "50px",
              maxWidth: "50px",
            }}
            appearance="transparent"
            icon={
              <Persona
                avatar={{
                  image: {
                    src: userData?.backgroundPicturePath || "",
                  },
                  name: userData?.emailAddress,
                }}
                secondaryText=""
                size="large"
              />
            }
            size="large"
          />
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItem>
              <Button
                onClick={gotoUserProfilePage}
                appearance="transparent"
                icon={<EditRegular fontSize={18} />}
              >
                {t("hbh.editProfile.label")}
              </Button>
            </MenuItem>

            <MenuItem>
              <Button
                onClick={() => logout()}
                appearance="transparent"
                icon={<SignOutRegular fontSize={18} />}
              >
                {t("header.SignOut")}
              </Button>
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </>
  );
};

const Container = styled.div`
  padding-top: 30px;
`;
