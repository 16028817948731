import { Card, Text } from "@fluentui/react-components";
import { SquareFilled } from "@fluentui/react-icons";
import { Flex } from "components/styled";
import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { darkModeAtom, selectedFullThemeAtom } from "store";
import styled from "styled-components";
import { darkStyledTheme, styledTheme } from "theme/mainTheme";
import { darkStyledPurpleTheme, styledPurpleTheme } from "theme/purpleTheme";
import { EThemeName, IStyledTheme } from "theme/types";

import { ColorPickerFluent } from "./ColorPickerFluent/ColorPickerFluent";

export function ThemeSelector() {
  const { t } = useTranslation();
  const darkMode = useAtomValue(darkModeAtom);
  const mainTheme = darkMode ? darkStyledTheme : styledTheme;
  const purpleTheme = darkMode ? darkStyledPurpleTheme : styledPurpleTheme;

  const [selectedFullTheme, setSelectedFullTheme] = useAtom(
    selectedFullThemeAtom
  );

  const theme = (
    darkMode ? selectedFullTheme?.dark : selectedFullTheme?.light
  ) as IStyledTheme;

  return (
    <Container>
      <Text>{t("hbh.themeSelector.label")}</Text>
      <Card
        style={{
          width: "100%",
        }}
      >
        <Flex $gap={"10px"}>
          <Text>{t("hbh.choseDefaultTheme.label")}</Text>
          <Wrap
            selectedColor={
              theme.name === EThemeName.main ? theme.palette.themePrimary : ""
            }
          >
            <SquareFilled
              onClick={() => {
                setSelectedFullTheme({
                  light: styledTheme,
                  dark: darkStyledTheme,
                });
              }}
              style={{
                color: mainTheme.palette.themePrimary,
                width: "30px",
                height: "30px",
              }}
              className="icon"
            />
          </Wrap>
          <Wrap
            selectedColor={
              theme.name === EThemeName.purple ? theme.palette.themePrimary : ""
            }
          >
            <SquareFilled
              onClick={() => {
                setSelectedFullTheme({
                  light: purpleTheme,
                  dark: darkStyledPurpleTheme,
                });

                // handleChangePrimaryColor(themeName);
                // setSelectedTheme(EThemeName.purple);
              }}
              style={{
                color: purpleTheme.palette.themePrimary,
                width: "30px",
                height: "30px",
              }}
              className="icon"
            />
          </Wrap>
        </Flex>
        <Flex direction="column" align="flex-start">
          <Text>{t("hbh.generateOwnTheme.label")}</Text>

          <ColorPickerFluent />
        </Flex>
      </Card>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Wrap = styled.div<{ selectedColor?: string }>`
  border: ${({ theme, selectedColor }) =>
    selectedColor
      ? `2px solid ${selectedColor}`
      : `1px solid ${theme.palette.neutralLight}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.palette.white};
  border-radius: 4px;

  @keyframes shine {
    from {
      -webkit-mask-position: 150%;
    }

    to {
      -webkit-mask-position: -50%;
    }
  }

  .icon {
    -webkit-mask-image: linear-gradient(
      -75deg,
      rgba(0, 0, 0, 0.6) 30%,
      #000 50%,
      rgba(0, 0, 0, 0.6) 70%
    );
    -webkit-mask-size: 200%;
    animation: shine 2s infinite;
  }
`;
