import {
  Body1,
  Card,
  CardHeader,
  CardPreview,
  Tag,
  TagGroup,
  Text,
} from "@fluentui/react-components";
import styled from "styled-components";
import ApprovalImg from "../../assets/approval.png";

export const StyledCard = styled(Card)<{
  $isSelected?: boolean;
  $canceled: boolean;
  $expired: boolean;
}>`
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  position: relative;
  padding: 0 !important;
  gap: 0;

  // opacity: ${({ $isSelected }) => ($isSelected ? "1" : "0.7")};

  box-shadow: ${({ $isSelected, $canceled, $expired, theme }) => {
    if ($expired) {
      return `0 2px 4px ${theme.palette.neutralLight}`;
    }
    if ($canceled) {
      return `0 2px 4px ${theme.palette.redDark}`;
    }
    if ($isSelected) {
      return `0 4px 8px ${theme.palette.themeLight}`;
    }
    return `0 2px 4px rgba(0, 0, 0, 0.1)`;
  }};

  .fui-CardHeader__image {
    margin-right: 5px !important;
  }
`;

export const StyledCardPreview = styled(CardPreview)`
  height: 250px;
  position: relative;
  background: ${(p) => p.theme.palette.white};
  margin: 0;
`;

export const BenefitImage = styled.img`
  width: 100%;
  cursor: pointer;
  object-fit: scale-down;
  // objectFit: "cover",
  // objectFit: "fill",
  // height: "250px",
`;

export const StyledCardHeader = styled(CardHeader)`
  padding: 5px 12px;
`;

export const StyledBody1 = styled(Body1)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PackageName = styled(Text)`
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
`;
export const StyledTagGroup = styled(TagGroup)`
  display: flex;
  gap: 7px;
  height: auto;
  flex-wrap: wrap;
  padding: 5px;
`;

export const Desc = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 12px 5px;
  height: 40px;
`;

export const ActionWrap = styled.div<{ $isAdd?: boolean }>`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 35px !important;
  height: 35px !important;
  background-color: ${({ theme, $isAdd }) =>
    $isAdd ? theme.palette.themeLighterAlt : theme.palette.themeLighterAlt};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 50%;
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 10px;
  z-index: 10;
`;

export const DocumentsWrap = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 35px !important;
  height: 35px !important;
  background-color: ${({ theme }) => theme.palette.neutralLighterAlt};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  border-bottom-right-radius: 50%;
  position: absolute;
  left: 10px;
  cursor: pointer;
  top: 10px;
  z-index: 12;
`;

export const PriceWrapInline = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  // background-color: ${({ theme }) => theme.palette.white};
  // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  position: relative;
  ::before {
    content: "";
    position: absolute;
    bottom: 16px;
    right: -5px;
    width: 10px; /* Length of the line */
    height: 1px; /* Thickness of the line */
    background-color: ${({ theme }) => theme.palette.black}; /* Line color */
    transform: rotate(-45deg);
    transform-origin: bottom right;
    opacity: 0.4;
  }

  ::after {
    content: "";
    position: absolute;
    bottom: 16px; /* Adjust for spacing */
    right: 2px; /* Adjust for spacing */
    width: 10px; /* Length of the line */
    height: 1px; /* Thickness of the line */
    background-color: ${({ theme }) => theme.palette.black}; /* Line color */
    transform: rotate(-45deg);
    transform-origin: bottom right;
    opacity: 0.4;
  }
`;

export const StyledTag = styled(Tag)`
  width: auto;
  height: auto;
  background-color: ${({ theme }) => theme.palette.neutralLighterAlt};

  .fui-Tag {
    padding: 0;
  }
  button {
    min-width: auto;
    padding: 0px;
    margin: 0px;
  }
  .fui-Tag__media {
    padding-right: 0px;
    display: flex;
    justify-content: center;
  }
`;

export const CanceledWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  opacity: 0.1;
  background-image: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.palette.redDark},
    ${({ theme }) => theme.palette.white}
  );
`;
export const ExpiredWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  opacity: 0.2;
  // background: ${({ theme }) => theme.palette.neutralLight};

  background: repeating-linear-gradient(
    45deg,
    ${({ theme }) => theme.palette.neutralLighterAlt},
    ${({ theme }) => theme.palette.neutralSecondaryAlt} 20px
  );
`;
export const WaitingForApprovalWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: black;
  background: ${({ theme }) => theme.palette.white};
  background-image: url(${ApprovalImg});
  // background: repeating-linear-gradient(
  //   45deg,
  //   ${({ theme }) => theme.palette.green},
  //   ${({ theme }) => theme.palette.greenDark} 40px
  // );
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export const UpgradeableWrap = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  cursor: pointer;
  bottom: 0px;
  z-index: 10;
`;
