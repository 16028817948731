import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";
import { CollapseWrapper } from "components/CollapseWrapper/CollapseWrapper";
import { PageContent } from "components/styled";
import useEmployee from "hooks/useEmployee";
import { useAtomValue } from "jotai";
import { EProfileTab } from "library/types";
import { selectedProfileTabAtom } from "store/UIProfilePage";
import { DependentsForm } from "./components/DependentsForm/DependentsForm";
import { EmployeeForm } from "./components/EmployeeForm/EmployeeForm";
import { ProfileHeader } from "./components/ProfileHeader/ProfileHeader";

export function ProfilePage() {
  const { employeeData } = useEmployee();

  const selectedTab = useAtomValue(selectedProfileTabAtom);

  if (!employeeData) {
    return <AppLogoSpinner />;
  }
  return (
    <>
      <PageContent>
        <ProfileHeader />
        <CollapseWrapper visible={selectedTab === EProfileTab.info}>
          <EmployeeForm />
        </CollapseWrapper>
        <CollapseWrapper visible={selectedTab === EProfileTab.dependents}>
          <DependentsForm />
        </CollapseWrapper>
      </PageContent>
    </>
  );
}
