import { CollapseWrapper } from "components/CollapseWrapper/CollapseWrapper";
import PackageUserSelect from "components/PackageUserSelect/PackageUserSelect";
import { BenefitSkeleton } from "components/Skeletons/BenefitSkeleton";
import { Flex } from "components/styled";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import { EBenefitContentTab, ICardBenefitPackage } from "library/types";
import { benefitContentTabAtom } from "store/UIBenefitsPage";
import styled from "styled-components";
import { EligiblePackages } from "./EligiblePackages";
import { EnrolledPackages } from "./EnrolledPackages";
import { ShowExpiredPackagesSwitch } from "./ShowExpiredPackagesSwitch";

type Props = {
  packageData: {
    eligiblePackages: ICardBenefitPackage[];
    enrolledPackages: ICardBenefitPackage[];
  };
  isLoading?: boolean;
};

export function BenefitsPageContent({ packageData, isLoading }: Props) {
  const selectedBenefitContentTab = useAtomValue(benefitContentTabAtom);

  return (
    <Wrap>
      {isLoading ? (
        <BenefitSkeleton />
      ) : (
        <>
          {/* <ContentSelector /> */}
          <Flex width={"100%"} $gap={"5px"} $justify="space-between">
            <UserSelectWrap>
              <PackageUserSelect view={"mobile"} />
            </UserSelectWrap>
            {selectedBenefitContentTab === EBenefitContentTab.enrolled && (
              <ShowExpiredPackagesSwitch />
            )}
          </Flex>
          <Wrap>
            <CollapseWrapper
              visible={
                selectedBenefitContentTab === EBenefitContentTab.eligible
              }
            >
              <EligiblePackages
                eligiblePackages={packageData.eligiblePackages}
                enrolledPackages={packageData.enrolledPackages}
              />
            </CollapseWrapper>
            <CollapseWrapper
              visible={
                selectedBenefitContentTab !== EBenefitContentTab.eligible
              }
            >
              <EnrolledPackages data={packageData.enrolledPackages} />
            </CollapseWrapper>
          </Wrap>
        </>
      )}
    </Wrap>
  );
}

const Wrap = styled.div`
  margin: 20px 0;
  @media only screen and ${devices.md} {
    margin: 40px 0;
  }
`;

const UserSelectWrap = styled.div`
  width: 220px;
  @media only screen and ${devices.md} {
    width: 300px;
  }
`;
