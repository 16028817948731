import DatePickerField from "components/controls/DatePickerField";
import InputField from "components/controls/InputField";
import { Grid } from "components/styled";
import { UploadFile } from "components/UploadFile/UploadFile";
import { subYears } from "date-fns";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { TInputAppearance } from "theme/types";

type Props = {
  isDependent?: boolean;
  isAddMode?: boolean;
  appearance?: TInputAppearance;
};

export function MainFields({
  isDependent,
  appearance = "underline",
  isAddMode,
}: Props) {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();

  return (
    <Grid>
      <InputField
        appearance={appearance}
        required
        label={t("hbh.firstName.label")}
        name="firstName"
        value={values["firstName"]}
        setFieldValue={setFieldValue}
        error={errors["firstName"]}
      />
      <InputField
        appearance={appearance}
        required
        label={t("hbh.lastName.label")}
        name="lastName"
        value={values["lastName"]}
        setFieldValue={setFieldValue}
        error={errors["lastName"]}
      />
      <InputField
        appearance={appearance}
        label={t("hbh.middleName.label")}
        name="middleName"
        value={values["middleName"]}
        setFieldValue={setFieldValue}
        error={errors["middleName"]}
      />

      <InputField
        appearance={appearance}
        required={isDependent ? false : true}
        label={t("hbh.email.label")}
        // disabled={(() => {
        //   if (isDependent) {
        //     return false;
        //   }
        //   return !isAddMode;
        // })()}
        name="emailAddress"
        value={values["emailAddress"]}
        setFieldValue={setFieldValue}
        error={errors["emailAddress"]}
      />
      <InputField
        appearance={appearance}
        required={isDependent ? false : true}
        name="mobilePhoneNumber"
        label={t("hbh.mobilePhoneNumber.label")}
        value={values["mobilePhoneNumber"]}
        setFieldValue={setFieldValue}
        error={errors["mobilePhoneNumber"]}
      />

      <InputField
        appearance={appearance}
        name="phoneNumber"
        label={t("hbh.phone.label")}
        value={values["phoneNumber"]}
        setFieldValue={setFieldValue}
        error={errors["phoneNumber"]}
      />

      <DatePickerField
        appearance={appearance}
        required
        maxDate={isDependent ? undefined : subYears(new Date(), 16)}
        name="dateOfBirth"
        value={values["dateOfBirth"]}
        setFieldValue={setFieldValue}
        label={t("hbh.dateOfBirth.label")}
        error={errors["dateOfBirth"]}
      />
      <UploadFile
        key={
          values[isDependent ? "dependentCoverPhoto" : "employeeCoverPhoto"]
            ?.path
        }
        setFieldValue={setFieldValue}
        fieldName={isDependent ? "dependentCoverPhoto" : "employeeCoverPhoto"}
        defaultValue={
          values[isDependent ? "dependentCoverPhoto" : "employeeCoverPhoto"]
        }
        label={t("hbh.chooseCoverPicture.label")}
      />
    </Grid>
  );
}
