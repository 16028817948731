import { Button } from "@fluentui/react-components";
import { SendFilled } from "@fluentui/react-icons";
import { TextareaField } from "components/controls/TextareaField";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { useSendPushNotification } from "hooks/useSendPushNotification";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export function NewMessage() {
  const theme = useAppTheme();
  const [message, addMessage] = useState("");
  const { t } = useTranslation();

  const { sendNotification } = useSendPushNotification();

  return (
    <Flex
      width={"100%"}
      $justify="space-between"
      align="flex-end"
      wrap="nowrap"
    >
      <TextareaField
        noLabel
        label=""
        noErrorSection
        name="message"
        value={message}
        setFieldValue={(name, value) => {
          addMessage(value);
        }}
        placeholder={t("hbh.writeMessage.label")}
        error={""}
        rows={3}
        maxLength={400}
        resize="none"
      />
      <Button
        appearance="primary"
        size="large"
        onClick={() => {
          console.log("click");
          sendNotification({
            text: "New Message From H&B",
            options: {
              body: message,
            },
          });
        }}
        icon={
          <SendFilled
            style={{
              color: theme.palette.white,
            }}
          />
        }
        shape="circular"
      />
    </Flex>
  );
}

export default NewMessage;
