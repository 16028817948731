import { Switch, SwitchOnChangeData } from "@fluentui/react-components";
import { useAtom } from "jotai";
import React from "react";
import { useTranslation } from "react-i18next";
import { showExpiredPackagesAtom } from "store/UIBenefitsPage";

export function ShowExpiredPackagesSwitch() {
  const { t } = useTranslation();
  const [showExpired, setShowExpired] = useAtom(showExpiredPackagesAtom);

  return (
    <Switch
      defaultChecked={showExpired}
      onChange={(_, data: SwitchOnChangeData) => {
        setShowExpired(!!data.checked);
      }}
      label={t("hbh.showExpired.label")}
      indicator={{
        style: {
          marginLeft: 0,
        },
      }}
      labelPosition="after"
    />
  );
}
