import { useAtom } from "jotai";
import { INotification } from "library/types";
import { getAxiosError } from "library/utils";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { notificationMessagesAtom } from "store";

export function useNotificationHandler() {
  const [notifications, setNotifications] = useAtom(notificationMessagesAtom);

  const handleClearNotification = useCallback(
    (message: INotification) => {
      setNotifications((prev) => {
        return prev?.filter((item) => item.id !== message.id);
      });
    },
    [setNotifications]
  );

  const handleAddNotification = useCallback(
    (newNotification: INotification) => {
      toast[newNotification.type](newNotification.text);
      setNotifications((prev: INotification[]) => {
        const alreadyExist = prev.some(
          (item) => item.id === newNotification.id
        );

        if (alreadyExist) {
          return [...prev];
        }
        return [
          ...prev,
          {
            ...newNotification,
            handleClearNotification: handleClearNotification,
          },
        ];
      });
    },
    [handleClearNotification, setNotifications]
  );

  const handleAddAxiosErrorNotification = useCallback(
    (error: any) => {
      const text = getAxiosError(error);
      const newNotification = {
        id: Date.now().toString(),
        text,
        type: "error",
      } as INotification;
      toast.error(text, {
        toastId: newNotification.id,
      });
      setNotifications(() => {
        const alreadyExist = notifications.some(
          (item) => item.id === newNotification.id
        );

        if (alreadyExist) {
          return [...notifications];
        }
        return [
          ...notifications,
          {
            ...newNotification,
            handleClearNotification: handleClearNotification,
          },
        ];
      });
    },
    [handleClearNotification, setNotifications, notifications]
  );

  const handleClearAllNotifications = useCallback(() => {
    setNotifications([]);
  }, [setNotifications]);

  return {
    handleAddNotification,
    notifications,
    handleClearAllNotifications,
    handleAddAxiosErrorNotification,
  };
}

export default useNotificationHandler;
