import { Card, makeStyles, SkeletonItem } from "@fluentui/react-components";
import { Flex } from "components/styled";
import styled from "styled-components";
const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },

  skeleton: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  fullWidth: {
    width: "100%",
  },

  cardBody: {
    padding: "10px",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  cardTab: {
    width: "70px",
  },
});

export function CardItemSkeleton() {
  const styles = useStyles();

  return (
    <StyledCard>
      <Flex direction="column" $gap={"0px"} width={"100%"}>
        <SkeletonItem size={72} shape="square" className={styles.fullWidth} />
        <SkeletonItem size={72} shape="square" className={styles.fullWidth} />
        <SkeletonItem size={72} shape="square" className={styles.fullWidth} />
      </Flex>
      <div className={styles.cardBody}>
        <SkeletonItem size={16} shape="square" className={styles.fullWidth} />
        <SkeletonItem size={20} shape="square" className={styles.fullWidth} />
        <SkeletonItem size={20} shape="square" className={styles.fullWidth} />
        <Flex>
          <SkeletonItem size={20} shape="square" className={styles.cardTab} />
          <SkeletonItem size={20} shape="square" className={styles.cardTab} />
          <SkeletonItem size={20} shape="square" className={styles.cardTab} />
        </Flex>
      </div>
      <SkeletonItem size={40} shape="square" className={styles.fullWidth} />
    </StyledCard>
  );
}

export default CardItemSkeleton;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  padding: 0 !important;
  height: 400px;
  > div {
    width: 100%;
  }
`;
