import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { IDependent } from "library/types";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateDependentData } from "./useGetDependentData";
import { useInvalidateEmployeeData } from "./useGetEmployeeData";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  employeeDependentTag: number;
  payload: IDependent;
};

const updateDependent = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeDependentTag,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employee/dependent/${countryCode}/${clientInternalNumberGOS}/${employeeDependentTag}`,
    payload
  );
  return response.data as IDependent;
};

export function useUpdateDependent(refetch = true) {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateDependentData = useInvalidateDependentData();
  const invalidateEmployeeData = useInvalidateEmployeeData();

  return useMutation(updateDependent, {
    mutationKey: MUTATION_KEYS.updateDependent,
    onSuccess: () => {
      if (refetch) {
        invalidateDependentData();
        invalidateEmployeeData();
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
