import useEmployee from "hooks/useEmployee";
import { devices } from "library/constants";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { StyledTooltip } from "components/styled";
import { isFuture, isWithinInterval } from "date-fns";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtomValue } from "jotai";
import { ETaxonomy, ICardBenefitPackage } from "library/types";
import { useMemo } from "react";
import { selectedClientAtom } from "store";
import HbhLogo from "../../../../assets/H&B_logo.png";
import { formatNumber } from "library/utils";

type Props = {
  enrolledData: ICardBenefitPackage[];
  isLoading?: boolean;
};

enum PaymentEntity {
  Company = "paidByCompany",
  Employee = "paidByEmployee",
  Dependent = "paidByDependent",
  CompanyForDependent = "paidByCompanyForDependent",
}

export function BenefitsPageHeader({ enrolledData, isLoading }: Props) {
  const { t } = useTranslation();
  const { employeeData } = useEmployee();
  const selectedClient = useAtomValue(selectedClientAtom);
  const currencyOptions = useTaxonomyOptions(ETaxonomy.Currency);

  const onlyActiveEnrollments = enrolledData.filter((benefitPackage) => {
    const activePackage = isWithinInterval(new Date(), {
      start: new Date(benefitPackage?.validFrom || ""),
      end: new Date(benefitPackage?.validTo || ""),
    });
    return activePackage;
  });

  const payments = useMemo(() => {
    if (!onlyActiveEnrollments.length) return null;
    const result = {};

    onlyActiveEnrollments.forEach((benefitPackage) => {
      const isPercentageValue = benefitPackage?.usingPercentagesForPaidBy;
      const currency = currencyOptions?.find(
        (item) => item.value === benefitPackage?.currencyCode
      )?.text;

      if (benefitPackage?.dateOfCancellation) {
        if (!isFuture(new Date(benefitPackage?.dateOfCancellation))) {
          return;
        }
      } //if benefit cancellation date is in future use it in calculation
      if (!result[currency]) {
        result[currency] = {
          paidByCompany: 0,
          paidByEmployee: 0,
          paidByDependent: 0,
          paidByCompanyForDependent: 0,
          currency,
        };
      }

      if (isPercentageValue) {
        if (benefitPackage?.isDependent) {
          result[currency].paidByCompanyForDependent +=
            ((benefitPackage?.price || 0) / 100) *
            (benefitPackage?.paidByCompany || 0);

          result[currency].paidByDependent +=
            ((benefitPackage?.price || 0) / 100) *
            (benefitPackage?.paidByEmployee || 0);
        } else {
          result[currency].paidByCompany +=
            ((benefitPackage?.price || 0) / 100) *
            (benefitPackage?.paidByCompany || 0);

          result[currency].paidByEmployee +=
            ((benefitPackage?.price || 0) / 100) *
            (benefitPackage?.paidByEmployee || 0);
        }
      } else {
        if (benefitPackage?.isDependent) {
          result[currency].paidByCompanyForDependent +=
            benefitPackage?.paidByCompany || 0;
          result[currency].paidByDependent +=
            benefitPackage?.paidByEmployee || 0;
        } else {
          result[currency].paidByCompany += benefitPackage?.paidByCompany || 0;
          result[currency].paidByEmployee +=
            benefitPackage?.paidByEmployee || 0;
        }
      }
    });

    return result;
  }, [enrolledData, currencyOptions, onlyActiveEnrollments]);

  const getPaymentByEntity = (valueName) => {
    return `${Object.keys(payments || {}).map(
      (key) =>
        `${formatNumber(payments?.[key][valueName])} ${
          payments?.[key]?.currency
        } `
    )}`;
  };

  return (
    <Container>
      <Title>
        {t("hbh.greeting.label", {
          name: employeeData?.firstName || "",
        })}
      </Title>
      <StyledTooltip
        relationship="label"
        content={{
          children: selectedClient?.messageToEmployees,
        }}
      >
        <Title>
          {selectedClient?.messageToEmployees ?? t("hbh.welcome.label")}
        </Title>
      </StyledTooltip>
      <img src={HbhLogo} alt="H&B Logo" />
      {!isLoading && payments ? (
        <Subtitle>
          {t("hbh.paymentInfoGeneral.label", {
            paidByCompany: getPaymentByEntity(PaymentEntity.Company),
            paidByEmployee: getPaymentByEntity(PaymentEntity.Employee),
            paidByCompanyForDependent: getPaymentByEntity(
              PaymentEntity.CompanyForDependent
            ),
            paidByDependent: getPaymentByEntity(PaymentEntity.Dependent),
          })}
        </Subtitle>
      ) : (
        <Subtitle>{t("hbh.noActiveEnrollments.label")}</Subtitle>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  @media only screen and ${devices.md} {
    gap: 15px;
  }
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  line-height: 25px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media only screen and ${devices.md} {
    font-size: 24px;
    line-height: 30px;

    max-width: 700px;
  }
`;

const Subtitle = styled.h2`
  max-width: 1200px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  line-height: normal;
  @media only screen and ${devices.md} {
    font-size: 18px;
  }
`;
