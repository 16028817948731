import { TinyColor } from "@ctrl/tinycolor";
import { Input, type InputProps, Label } from "@fluentui/react-components";
import styled from "styled-components";

export const InputHexField = ({
  label = "Hex",
  id,
  value,
  onChange,
}: {
  label?: string;
  id: string;
  value: string;
  onChange: InputProps["onChange"];
}) => {
  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = new TinyColor(e.target.value);
    if (!value.isValid) {
      e.target.setAttribute("aria-invalid", "true");
    } else {
      e.target.removeAttribute("aria-invalid");
    }
  };

  return (
    <Container>
      <Label htmlFor={id}>{label}</Label>
      <Input
        style={{
          width: "80px",
        }}
        value={value}
        id={id}
        onChange={onChange}
        onBlur={handleOnBlur}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
