import { Collapse } from "@fluentui/react-motion-components-preview";

type Props = {
  visible: boolean;
  children?: JSX.Element;
};

export const CollapseWrapper = ({ visible, children }: Props) => {
  return (
    <Collapse visible={visible}>
      <div>{children}</div>
    </Collapse>
  );
};
