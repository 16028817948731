import {
  Label,
  SpinButton,
  type SpinButtonProps,
  useId,
} from "@fluentui/react-components";
import styled from "styled-components";

type RgbKey = "r" | "g" | "b";

export const InputRgbField = ({
  value,
  onChange,
  label,
  name,
}: {
  value: number;
  label: string;
  name: RgbKey;
  onChange?: SpinButtonProps["onChange"];
}) => {
  const id = useId(`${label.toLowerCase()}-input`);

  return (
    <Container>
      <Label htmlFor={id}>{label}</Label>
      <SpinButton
        style={{
          width: "50px",
        }}
        min={0}
        max={255}
        data-name={name}
        value={value}
        id={id}
        onChange={onChange}
        name={name}
      />
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
