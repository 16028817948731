import { IStyledTheme } from "theme/types";

export const styledPurpleTheme: IStyledTheme = {
  name: "purple",

  palette: {
    themeDarker: "#2b0035",
    themeDark: "#3b0047",
    themeDarkAlt: "#4a0059",
    themePrimary: "#59006b",
    themeSecondary: "#6a157b",
    themeTertiary: "#934f9f",
    themeLight: "#c59fc8",
    themeLighter: "#e0cadb",
    themeLighterAlt: "#f7f1f6",
    black: "#000000",
    blackTranslucent40: "rgba(0,0,0,.4)",
    themeBackground: "#f8f9fe",
    neutralDark: "#201f1e",
    neutralPrimary: "#323130",
    neutralPrimaryAlt: "#3b3a39",
    neutralSecondary: "#605e5c",
    neutralSecondaryAlt: "#8a8886",
    neutralTertiary: "#a19f9d",
    neutralTertiaryAlt: "#c8c6c4",
    neutralQuaternary: "#d0d0d0",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralLight: "#edebe9",
    neutralLighter: "#f3f2f1",
    neutralLighterAlt: "#faf9f8",
    accent: "#59006b",
    white: "#ffffff",
    whiteTranslucent40: "rgba(255,255,255,.4)",
    yellowDark: "#806400",
    yellow: "#b38e00",
    yellowLight: "#d4bf00",
    orange: "#a23401",
    orangeLight: "#c54300",
    orangeLighter: "#e56700",
    redDark: "#7a1a2c",
    red: "#b40023",
    magentaDark: "#400040",
    magenta: "#760076",
    magentaLight: "#a500a5",
    purpleDark: "#2d0035",
    purple: "#59006b",
    purpleLight: "#a08ab4",
    blueDark: "#1d0035",
    blueMid: "#3a006b",
    blue: "#7500b3",
    blueLight: "#a400d9",
    tealDark: "#2d004b",
    teal: "#530075",
    tealLight: "#8000a3",
    greenDark: "#1c004b",
    green: "#43007a",
    greenLight: "#6b00a1",
    titanWhite: "#eeeeff",
  },
  effects: {
    elevation4:
      "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
    elevation8:
      "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)",
    elevation16:
      "0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108)",
    elevation64:
      "0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)",
    roundedCorner2: "2px",
    roundedCorner4: "4px",
    roundedCorner6: "6px",
  },

  semanticColors: {
    bodyBackground: "#1E1B29",
    bodyFrameBackground: "#1E1B29",
    accentButtonText: "#1E1B29",
    buttonBackground: "#1E1B29",
    primaryButtonText: "#1E1B29",
    primaryButtonTextHovered: "#1E1B29",
    primaryButtonTextPressed: "#1E1B29",
    inputBackground: "#1E1B29",
    inputForegroundChecked: "#1E1B29",
    listBackground: "#1E1B29",
    menuBackground: "#1E1B29",
    cardStandoutBackground: "#1E1B29",
    bodyTextChecked: "#f8f8f8",
    buttonTextCheckedHovered: "#f8f8f8",
    link: "#9A5CFF",
    primaryButtonBackground: "#9A5CFF",
    inputBackgroundChecked: "#9A5CFF",
    inputIcon: "#9A5CFF",
    inputFocusBorderAlt: "#9A5CFF",
    menuIcon: "#9A5CFF",
    menuHeader: "#9A5CFF",
    accentButtonBackground: "#9A5CFF",
    primaryButtonBackgroundPressed: "#B380FF",
    inputBackgroundCheckedHovered: "#B380FF",
    inputIconHovered: "#B380FF",
    linkHovered: "#CC99FF",
    primaryButtonBackgroundHovered: "#A066FF",
    inputPlaceholderBackgroundChecked: "#2B2040",
    bodyBackgroundChecked: "#3A2B50",
    bodyFrameDivider: "#3A2B50",
    bodyDivider: "#3A2B50",
    variantBorder: "#3A2B50",
    buttonBackgroundCheckedHovered: "#3A2B50",
    buttonBackgroundPressed: "#3A2B50",
    listItemBackgroundChecked: "#3A2B50",
    listHeaderBackgroundPressed: "#3A2B50",
    menuItemBackgroundPressed: "#3A2B50",
    menuItemBackgroundChecked: "#3A2B50",
    bodyBackgroundHovered: "#2A2340",
    buttonBackgroundHovered: "#2A2340",
    buttonBackgroundDisabled: "#2A2340",
    buttonBorderDisabled: "#2A2340",
    primaryButtonBackgroundDisabled: "#2A2340",
    disabledBackground: "#2A2340",
    listItemBackgroundHovered: "#2A2340",
    listHeaderBackgroundHovered: "#2A2340",
    menuItemBackgroundHovered: "#2A2340",
    primaryButtonTextDisabled: "#4C4270",
    disabledSubtext: "#4C4270",
    listItemBackgroundCheckedHovered: "#55447A",
    disabledBodyText: "#D0D0D0",
    variantBorderHovered: "#D0D0D0",
    buttonTextDisabled: "#D0D0D0",
    inputIconDisabled: "#D0D0D0",
    disabledText: "#D0D0D0",
    bodyText: "#FFFFFF",
    actionLink: "#FFFFFF",
    buttonText: "#FFFFFF",
    inputBorderHovered: "#FFFFFF",
    inputText: "#FFFFFF",
    listText: "#FFFFFF",
    menuItemText: "#FFFFFF",
    bodyStandoutBackground: "#252032",
    defaultStateBackground: "#252032",
    actionLinkHovered: "#F4F4F4",
    buttonTextHovered: "#F4F4F4",
    buttonTextChecked: "#F4F4F4",
    buttonTextPressed: "#F4F4F4",
    inputTextHovered: "#F4F4F4",
    menuItemTextHovered: "#F4F4F4",
    bodySubtext: "#D0D0D0",
    focusBorder: "#D0D0D0",
    inputBorder: "#D0D0D0",
    smallInputBorder: "#D0D0D0",
    inputPlaceholderText: "#D0D0D0",
    buttonBorder: "#8A88B0",
    disabledBodySubtext: "#686868",
    disabledBorder: "#686868",
    buttonBackgroundChecked: "#686868",
    menuDivider: "#686868",
    cardShadow:
      "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
    cardShadowHovered: "0 0 1px #D0D0D0",
    primaryButtonBorder: "transparent",
    errorText: "#A4262C",
    messageText: "#323130",
    messageLink: "#7944A5",
    messageLinkHovered: "#593277",
    infoIcon: "#605E7C",
    errorIcon: "#A80000",
    blockingIcon: "#FDE7E9",
    warningIcon: "#797775",
    severeWarningIcon: "#D83B01",
    successIcon: "#107C10",
    infoBackground: "#F3F2F1",
    errorBackground: "#FDE7E9",
    blockingBackground: "#FDE7E9",
    warningBackground: "#FFF4CE",
    severeWarningBackground: "#FED9CC",
    successBackground: "#DFF6DD",
    warningHighlight: "#FFB900",
    successText: "#107C10",
    successHighlight: "#9fd7b2",

    listTextColor: "#323130",
    warningText: "#323130",
  },
  isDark: false,
};

export const darkStyledPurpleTheme: IStyledTheme = {
  name: "purple",

  palette: {
    themeDarker: "#90cfff",
    themeDark: "#68bdff",
    themeDarkAlt: "#4cb1ff",
    themePrimary: "#38a9ff",
    themeSecondary: "#3195e0",
    themeTertiary: "#226599",
    themeLight: "#11334d",
    themeLighter: "#091b29",
    themeLighterAlt: "#02070a",
    black: "#f8f8f8",
    themeBackground: "#151515",

    blackTranslucent40: "rgba(0,0,0,.4)",
    neutralDark: "#f4f4f4",
    neutralPrimary: "#ffffff",
    neutralPrimaryAlt: "#dadada",
    neutralSecondary: "#d0d0d0",
    neutralSecondaryAlt: "#8a8886",
    neutralTertiary: "#c8c8c8",
    neutralTertiaryAlt: "#686868",
    neutralQuaternary: "#494949",
    neutralQuaternaryAlt: "#424242",
    neutralLight: "#393939",
    neutralLighter: "#2a2a2a",
    neutralLighterAlt: "#212121",
    accent: "#38a9ff",
    white: "#171717",
    whiteTranslucent40: "rgba(255,255,255,.4)",
    yellowDark: "#d29200",
    yellow: "#ffb900",
    yellowLight: "#fff100",
    orange: "#d83b01",
    orangeLight: "#ea4300",
    orangeLighter: "#ff8c00",
    redDark: "#a4262c",
    red: "#e81123",
    magentaDark: "#5c005c",
    magenta: "#b4009e",
    magentaLight: "#e3008c",
    purpleDark: "#32145a",
    purple: "#5c2d91",
    purpleLight: "#b4a0ff",
    blueDark: "#002050",
    blueMid: "#00188f",
    blue: "#0078d4",
    blueLight: "#00bcf2",
    tealDark: "#004b50",
    teal: "#008272",
    tealLight: "#00b294",
    greenDark: "#004b1c",
    green: "#107c10",
    greenLight: "#bad80a",
    titanWhite: "#444453",
  },
  effects: {
    elevation4:
      "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
    elevation8:
      "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)",
    elevation16:
      "0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108)",
    elevation64:
      "0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)",
    roundedCorner2: "2px",
    roundedCorner4: "4px",
    roundedCorner6: "6px",
  },

  semanticColors: {
    bodyBackground: "#181524",
    bodyFrameBackground: "#181524",
    accentButtonText: "#181524",
    buttonBackground: "#181524",
    primaryButtonText: "#181524",
    primaryButtonTextHovered: "#181524",
    primaryButtonTextPressed: "#181524",
    inputBackground: "#181524",
    inputForegroundChecked: "#181524",
    listBackground: "#181524",
    menuBackground: "#181524",
    cardStandoutBackground: "#181524",
    bodyTextChecked: "#dcdcdc",
    buttonTextCheckedHovered: "#dcdcdc",
    link: "#a17cff",
    primaryButtonBackground: "#a17cff",
    inputBackgroundChecked: "#a17cff",
    inputIcon: "#a17cff",
    inputFocusBorderAlt: "#a17cff",
    menuIcon: "#a17cff",
    menuHeader: "#a17cff",
    accentButtonBackground: "#a17cff",
    primaryButtonBackgroundPressed: "#bb8aff",
    inputBackgroundCheckedHovered: "#bb8aff",
    inputIconHovered: "#bb8aff",
    linkHovered: "#d4a6ff",
    primaryButtonBackgroundHovered: "#b28fff",
    inputPlaceholderBackgroundChecked: "#241a35",
    bodyBackgroundChecked: "#2e2340",
    bodyFrameDivider: "#2e2340",
    bodyDivider: "#2e2340",
    variantBorder: "#2e2340",
    buttonBackgroundCheckedHovered: "#2e2340",
    buttonBackgroundPressed: "#2e2340",
    listItemBackgroundChecked: "#2e2340",
    listHeaderBackgroundPressed: "#2e2340",
    menuItemBackgroundPressed: "#2e2340",
    menuItemBackgroundChecked: "#2e2340",
    bodyBackgroundHovered: "#231c31",
    buttonBackgroundHovered: "#231c31",
    buttonBackgroundDisabled: "#231c31",
    buttonBorderDisabled: "#231c31",
    primaryButtonBackgroundDisabled: "#231c31",
    disabledBackground: "#231c31",
    listItemBackgroundHovered: "#231c31",
    listHeaderBackgroundHovered: "#231c31",
    menuItemBackgroundHovered: "#231c31",
    primaryButtonTextDisabled: "#4e426b",
    disabledSubtext: "#4e426b",
    listItemBackgroundCheckedHovered: "#59457f",
    disabledBodyText: "#bababa",
    variantBorderHovered: "#bababa",
    buttonTextDisabled: "#bababa",
    inputIconDisabled: "#bababa",
    disabledText: "#bababa",
    bodyText: "#ffffff",
    actionLink: "#ffffff",
    buttonText: "#ffffff",
    inputBorderHovered: "#ffffff",
    inputText: "#ffffff",
    listText: "#ffffff",
    menuItemText: "#ffffff",
    bodyStandoutBackground: "#1f192c",
    defaultStateBackground: "#1f192c",
    actionLinkHovered: "#e0e0e0",
    buttonTextHovered: "#e0e0e0",
    buttonTextChecked: "#e0e0e0",
    buttonTextPressed: "#e0e0e0",
    inputTextHovered: "#e0e0e0",
    menuItemTextHovered: "#e0e0e0",
    bodySubtext: "#bababa",
    focusBorder: "#bababa",
    inputBorder: "#bababa",
    smallInputBorder: "#bababa",
    inputPlaceholderText: "#bababa",
    buttonBorder: "#9489ab",
    disabledBodySubtext: "#686868",
    disabledBorder: "#686868",
    buttonBackgroundChecked: "#686868",
    menuDivider: "#686868",
    cardShadow:
      "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
    cardShadowHovered: "0 0 1px #bababa",
    primaryButtonBorder: "transparent",
    errorText: "#a4262c",
    messageText: "#323130",
    messageLink: "#7e58a7",
    messageLinkHovered: "#674089",
    infoIcon: "#5a567b",
    errorIcon: "#a80000",
    blockingIcon: "#fde7e9",
    warningIcon: "#797775",
    severeWarningIcon: "#d83b01",
    successIcon: "#107c10",
    infoBackground: "#302a3e",
    errorBackground: "#fde7e9",
    blockingBackground: "#fde7e9",
    warningBackground: "#fff4ce",
    severeWarningBackground: "#fed9cc",
    successBackground: "#dff6dd",
    warningHighlight: "#ffb900",
    successText: "#107c10",
    listTextColor: "#323130",
    successHighlight: "#9fd7b2",
    warningText: "#323130",
  },
  isDark: true,
};
