import {
  ArchiveRegular,
  CodeBlockRegular,
  DocumentCssRegular,
  DocumentJavaRegular,
  DocumentJavascriptRegular,
  DocumentPdfRegular,
  DocumentRegular,
  HardDriveRegular,
  RocketRegular,
  SaveCopyRegular,
  SettingsRegular,
  SoundWaveCircleRegular,
  VideoClipRegular,
} from "@fluentui/react-icons";
import {
  FileCodeIcon,
  FileHTMLIcon,
  FileJAVAIcon,
  ImagePixelIcon,
  TextDocumentIcon,
} from "@fluentui/react-icons-mdl2";
import {
  ExcelDocumentIcon,
  PowerPointDocumentIcon,
  WordDocumentIcon,
} from "@fluentui/react-icons-mdl2-branded";

export const STORAGE_KEYS = {
  darkMode: "dark-mode",
  appLang: "app-lang",
  isListBenefitView: "is-list-benefit-view",
  selectedFullTheme: "selected-full-theme",
};

export const QUERY_KEYS = {
  taxonomies: "taxonomies",
  userClients: "userClients",
  employeeData: "employeeData",
  dependentData: "dependentData",
  companyBenefitPackageViews: "companyBenefitPackageViews",
  employeeEnrollments: "employeeEnrollments",
  enrollmentCancellationPossibilityDate:
    "enrollmentCancellationPossibilityDate",
};

export const MUTATION_KEYS = {
  updateEmployee: "updateEmployee",
  createDependent: "createDependent",
  updateDependent: "updateDependent",
  deleteDependent: "deleteDependent",
  enrollPackage: "enrollPackage",
  cancelEnrollment: "cancelEnrollment",
  upgradeEnrollment: "upgradeEnrollment",
};

export const ROUTE_PARAMS = {};

export const fileExtensionIcons = {
  // PowerPoint extensions
  pptx: <PowerPointDocumentIcon />,
  ppt: <PowerPointDocumentIcon />,
  ppsx: <PowerPointDocumentIcon />,
  pps: <PowerPointDocumentIcon />,
  potx: <PowerPointDocumentIcon />,
  pot: <PowerPointDocumentIcon />,

  // Excel extensions
  xlsx: <ExcelDocumentIcon />,
  xls: <ExcelDocumentIcon />,
  xlsm: <ExcelDocumentIcon />,
  xltx: <ExcelDocumentIcon />,
  xlt: <ExcelDocumentIcon />,

  // Word extensions
  docx: <WordDocumentIcon />,
  doc: <WordDocumentIcon />,
  dotx: <WordDocumentIcon />,
  dot: <WordDocumentIcon />,

  // PDF
  pdf: <DocumentPdfRegular />,

  // Images
  jpg: <ImagePixelIcon />,
  jpeg: <ImagePixelIcon />,
  png: <ImagePixelIcon />,
  gif: <ImagePixelIcon />,
  bmp: <ImagePixelIcon />,
  svg: <ImagePixelIcon />,
  tiff: <ImagePixelIcon />,

  // Videos
  mp4: <VideoClipRegular />,
  avi: <VideoClipRegular />,
  mkv: <VideoClipRegular />,
  mov: <VideoClipRegular />,
  wmv: <VideoClipRegular />,

  // Audio
  mp3: <SoundWaveCircleRegular />,
  wav: <SoundWaveCircleRegular />,
  flac: <SoundWaveCircleRegular />,
  aac: <SoundWaveCircleRegular />,
  ogg: <SoundWaveCircleRegular />,

  // Archives
  zip: <ArchiveRegular />,
  rar: <ArchiveRegular />,
  "7z": <ArchiveRegular />,
  tar: <ArchiveRegular />,
  gz: <ArchiveRegular />,

  // Text files
  txt: <TextDocumentIcon />,
  csv: <ExcelDocumentIcon />,
  log: <DocumentRegular />,

  // Code files
  js: <DocumentJavascriptRegular />,
  html: <FileCodeIcon />,
  css: <DocumentCssRegular />,
  json: <FileCodeIcon />,
  xml: <FileCodeIcon />,
  ts: <FileCodeIcon />,
  py: <FileCodeIcon />,
  java: <DocumentJavaRegular />,
  c: <FileCodeIcon />,
  cpp: <FileCodeIcon />,

  // Other
  exe: <RocketRegular />,
  dll: <SettingsRegular />,
  iso: <HardDriveRegular />,
  bak: <SaveCopyRegular />,
};
