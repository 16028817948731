import { requestPermission } from "library/utils";
import React, { useCallback } from "react";

import logo from "../assets/H&B_logo.png";

export function useSendPushNotification() {
  const sendNotification = ({
    text,
    options,
  }: {
    text: string;
    options?: NotificationOptions;
  }) => {
    const defaultOptions = {
      body: "",
      icon: logo, // Ensure the path to the icon is correct.
      vibrate: [100, 50, 100], // Optional vibration pattern.
      //tag: "test-notification", // Optional: helps avoid duplicate notifications.
    };

    if ("Notification" in window && Notification.permission === "granted") {
      return new Notification(text, {
        ...defaultOptions,
        ...options,
      });
    } else {
      requestPermission();
    }
  };

  return {
    sendNotification,
  };
}
