import { darkStyledTheme, styledTheme } from "theme/mainTheme";
import { IStyledTheme } from "theme/types";

import { TinyColor } from "@ctrl/tinycolor";

function adjustThemeColors(
  newThemePrimary,
  originalThemeObject,
  originalThemePrimary
): any {
  // Helper function to convert color to HSL
  function toHSL(color) {
    const { h, s, l } = new TinyColor(color).toHsl();
    return { h, s, l };
  }

  // Helper function to convert HSL back to color
  function fromHSL(hsl) {
    return new TinyColor(hsl).toHexString();
  }

  // Generate theme variations (light/dark)
  function generateTheme(baseHSL, adjustLightness) {
    const newTheme = {};
    for (const [key, color] of Object.entries(originalThemeObject)) {
      const colorHSL = toHSL(color);

      // Calculate relative HSL differences
      const diffH = colorHSL.h - originalThemePrimaryHSL.h;
      const diffS = colorHSL.s - originalThemePrimaryHSL.s;
      const diffL = colorHSL.l - originalThemePrimaryHSL.l;

      // Apply the differences to the new `themePrimary` color
      const adjustedHSL = {
        h: (baseHSL.h + diffH + 360) % 360, // Keep hue within 0-360
        s: Math.min(Math.max(baseHSL.s + diffS, 0), 1), // Clamp saturation to 0-1
        l: adjustLightness(baseHSL.l + diffL), // Adjust lightness dynamically
      };

      // Convert back to a color and assign it to the theme
      newTheme[key] = fromHSL(adjustedHSL);
    }
    return newTheme;
  }

  // Convert the original `themePrimary` to HSL
  const originalThemePrimaryHSL = toHSL(originalThemePrimary);
  const newThemePrimaryHSL = toHSL(newThemePrimary);

  // Adjust lightness for light and dark themes
  const lightTheme = generateTheme(newThemePrimaryHSL, (l) =>
    Math.min(l + 0.2, 1)
  ); // Increase lightness
  const darkTheme = generateTheme(newThemePrimaryHSL, (l) =>
    Math.max(l - 0.2, 0)
  ); // Decrease lightness

  return { lightTheme, darkTheme };
}

export function generateTheme(newThemePrimary: string): {
  lightTheme: IStyledTheme;
  darkTheme: IStyledTheme;
} {
  return {
    lightTheme: {
      name: "generated-light-theme",
      palette: {
        ...(adjustThemeColors(
          newThemePrimary,
          styledTheme.palette,
          styledTheme.palette.themePrimary
        ).lightTheme as IStyledTheme["palette"]),
        themePrimary: newThemePrimary,
      },
      // effects: adjustThemeColors(
      //   newThemePrimary,
      //   styledTheme.effects,
      //   styledTheme.palette.themePrimary
      // ).lightTheme as IStyledTheme["effects"],
      effects: styledTheme.effects,
      semanticColors: adjustThemeColors(
        newThemePrimary,
        styledTheme.semanticColors,
        styledTheme.palette.themePrimary
      ).lightTheme as IStyledTheme["semanticColors"],
      isDark: false,
    },
    darkTheme: {
      name: "generated-dark-theme",

      palette: {
        ...(adjustThemeColors(
          newThemePrimary,
          darkStyledTheme.palette,
          darkStyledTheme.palette.themePrimary
        ).darkTheme as IStyledTheme["palette"]),
        themePrimary: newThemePrimary,
      },
      // effects: adjustThemeColors(
      //   newThemePrimary,
      //   darkStyledTheme.effects,
      //   darkStyledTheme.palette.themePrimary
      // ).darkTheme as IStyledTheme["effects"],
      effects: darkStyledTheme.effects,
      semanticColors: adjustThemeColors(
        newThemePrimary,
        darkStyledTheme.semanticColors,
        darkStyledTheme.palette.themePrimary
      ).darkTheme as IStyledTheme["semanticColors"],
      isDark: false,
    },
  };
}

// Example usage:
// const { lightTheme, darkTheme } = generateTheme("#59006b", "#6a157b");

// console.log(lightTheme);
// console.log(darkTheme);
