import {
  Avatar,
  Button,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuProps,
  MenuTriggerChildProps,
  Text,
  Tooltip,
} from "@fluentui/react-components";
import { devices } from "library/constants";
import { useGetUserClients } from "state/useGetUserClients";
import styled from "styled-components";

import { Menu, MenuTrigger } from "@fluentui/react-components";
import {
  ChevronDownRegular,
  DocumentBulletList20Regular,
} from "@fluentui/react-icons";
import { ButtonWithTooltip } from "components/ButtonWithTooltip/ButtonWithTooltip";
import { DocumentsDialog } from "components/DocumentsDialog/DocumentsDialog";
import { Flex } from "components/styled";
import { useAtom } from "jotai";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectedClientAtom } from "store";

type Props = {
  onlyMobile?: boolean;
  onlyDesktop?: boolean;
};

export function SelectClient({ onlyMobile, onlyDesktop }: Props) {
  const { t } = useTranslation();
  const { data: clientOptions = [] } = useGetUserClients();
  const [open, setOpen] = useState(false);

  const [selectedClient, setSelectedClient] = useAtom(selectedClientAtom);

  const [showDocuments, setShowDocuments] = useState(false);

  const onOpenChange: MenuProps["onOpenChange"] = (e, data) => {
    setOpen(data.open);
  };

  const setDocumentOpenState = useCallback((state) => {
    setShowDocuments(state);
  }, []);

  const filteredClientOptions =
    clientOptions?.filter(
      (item) =>
        item.clientInternalNumberGos !== selectedClient?.clientInternalNumberGos
    ) || [];

  if (!clientOptions.length) return null;

  const withMultiClients = Boolean(clientOptions?.length > 1);

  const clientDocuments = selectedClient?.attachments || [];

  return (
    <>
      <Container
        $withMultiClients={withMultiClients}
        $onlyMobile={onlyMobile}
        $onlyDesktop={onlyDesktop}
      >
        <Menu open={open} onOpenChange={onOpenChange}>
          <MenuTrigger disableButtonEnhancement>
            {(props: MenuTriggerChildProps<"button">) => (
              <ButtonWrap $onlyMobile={onlyMobile}>
                <Button
                  {...props}
                  appearance="transparent"
                  style={{
                    height: "100%",
                    paddingRight: "0",
                  }}
                  icon={<ChevronDownRegular />}
                  iconPosition="after"
                >
                  <Tooltip
                    content={selectedClient?.clientName || ""}
                    relationship="description"
                  >
                    <Avatar
                      name={selectedClient?.clientName}
                      className="mobileViewClient"
                    />
                  </Tooltip>
                  <Text
                    className="desktopViewClient"
                    // onClick={triggerProps.onClick}
                  >
                    {onlyDesktop && selectedClient?.backgroundPicturePath && (
                      <ClientBgImg
                        name={selectedClient?.clientName}
                        shape="square"
                        image={{
                          src: selectedClient?.backgroundPicturePath,
                        }}
                      />
                    )}
                    {selectedClient?.clientName}
                  </Text>
                </Button>
              </ButtonWrap>
            )}
          </MenuTrigger>

          {withMultiClients ? (
            <MenuPopover>
              <MenuList>
                {filteredClientOptions?.map((item) => (
                  <MenuItem
                    key={item.clientInternalNumberGos}
                    onClick={() => {
                      setSelectedClient(item);
                    }}
                  >
                    <Flex>
                      <ClientBgImg
                        name={item?.clientName}
                        shape="square"
                        image={{
                          src: item?.backgroundPicturePath,
                        }}
                      />
                      {item.clientName}
                    </Flex>
                  </MenuItem>
                ))}
              </MenuList>
            </MenuPopover>
          ) : (
            <></>
          )}
        </Menu>
        {clientDocuments?.length ? (
          <ButtonWithTooltip
            tooltipContent={t("hbh.viewCompanyDocuments.label", {
              company: selectedClient?.clientName,
            })}
            handleClick={() => {
              setShowDocuments(true);
            }}
            icon={<DocumentBulletList20Regular />}
          />
        ) : null}
      </Container>
      {showDocuments && clientDocuments?.length && (
        <DocumentsDialog
          title={t("hbh.companyDocuments.label")}
          open={showDocuments}
          setOpen={setDocumentOpenState}
          documents={clientDocuments}
        />
      )}
    </>
  );
}

const Container = styled.div<{
  $withMultiClients?: boolean;
  $onlyMobile?: boolean;
  $onlyDesktop?: boolean;
}>`
  display: ${({ $onlyDesktop }) => ($onlyDesktop ? "none" : "flex")};

  .mobileViewClient {
    display: flex;
  }
  .desktopViewClient {
    display: none;
  }
  .clientsSelectBtn {
    min-width: 50px;
    button {
      max-width: 50px;
      min-width: 50px;
    }
  }

  .fui-Button__icon {
    display: ${({ $withMultiClients }) =>
      $withMultiClients ? "flex" : "none !important"};
  }

  .fui-Button__icon {
    color: ${({ theme }) => theme.palette.white};
  }

  button:disabled,
  button:focus,
  button:active {
    color: ${({ theme }) => theme.palette.themePrimary} !important;
    cursor: default !important;
  }

  @media only screen and ${devices.md} {
    .mobileViewClient {
      display: none;
    }
    .desktopViewClient {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .clientsSelectBtn {
      button {
        max-width: fit-content;
      }
    }

    .fui-Button__icon {
      color: inherit;
      font-size: 14px;
      font-weight: 600;
    }

    display: ${({ $onlyMobile }) => ($onlyMobile ? "none" : "flex")};
    height: 100%;
    color: ${({ theme }) => theme.palette.themePrimary};
  }
`;

const ClientBgImg = styled(Avatar)`
  width: 30px;
  height: 30px;
`;

const ButtonWrap = styled.div<{ $onlyMobile?: boolean }>`
  ${({ $onlyMobile }) =>
    $onlyMobile &&
    `
    width: 80px;
  `}
`;
