import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  Text,
  Textarea,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { Flex } from "components/styled";
import useEmployee from "hooks/useEmployee";
import { useIsMobile } from "hooks/useIsMobile";
import { useAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { chatPanelOpenAtom } from "store";
import styled from "styled-components";
import { ChatIcon } from "./ChatIcon";

import { useAppTheme } from "hooks/useAppTheme";
import chatLogo from "../../assets/chatIcon.svg";
import { TextareaField } from "components/controls/TextareaField";
import NewMessage from "./NewMessage";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { RatingDialog } from "./RatingDialog";

// -- Fabric UI
const messages = [
  {
    id: 1,
    message: "Can you tell me about my benefits in the company?",
    sender: "user",
  },
  {
    id: 2,
    message:
      "Sure! You are eligible for health insurance, a retirement plan, and paid time off.",
    sender: "bot",
  },
  {
    id: 3,
    message: "That's great! How many days of paid time off do I get?",
    sender: "user",
  },
  {
    id: 4,
    message: "You currently have 20 days of paid time off available annually.",
    sender: "bot",
  },
  {
    id: 5,
    message: "Can I carry over unused paid time off to the next year?",
    sender: "user",
  },
  {
    id: 6,
    message: "Yes, you can carry over up to 5 unused days to the next year.",
    sender: "bot",
  },
  {
    id: 7,
    message: "What about health insurance? What does it cover?",
    sender: "user",
  },
  {
    id: 8,
    message:
      "Your health insurance covers medical, dental, and vision care. It also includes wellness checkups.",
    sender: "bot",
  },
  {
    id: 9,
    message: "Thank you! This information is really helpful.",
    sender: "user",
  },
  {
    id: 10,
    message: "What about health insurance? What does it cover?",
    sender: "user",
  },
  {
    id: 11,
    message:
      "Your health insurance covers medical, dental, and vision care. It also includes wellness checkups.",
    sender: "bot",
  },
  {
    id: 12,
    message: "Thank you! This information is really helpful.",
    sender: "user",
  },
];
interface IProps {
  onNavigationStateChange: (value: boolean) => void;
  navigationPanelOpen: boolean;
  navigation?: JSX.Element;
}

export function ChatPanel() {
  const theme = useAppTheme();
  const [ratingOpen, setRatingOpen] = useState(false);
  const [chatPanelOpen, setChatPanelOpen] = useAtom(chatPanelOpenAtom);
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { employeeData } = useEmployee();
  const toggleChatPanelState = useCallback(() => {
    setChatPanelOpen((prev) => !prev);
  }, []);

  const handleChatPanelOpen = useCallback((state) => {
    setChatPanelOpen(state);
  }, []);

  const handleCloseRatingDialog = useCallback(() => {
    setRatingOpen(false);
  }, []);

  return (
    <>
      <OverlayDrawer
        position="end"
        // size="small"
        open={chatPanelOpen}
        onOpenChange={(state) => {
          setChatPanelOpen(false);
        }}
        style={{
          top: "50px",
          width: isMobile ? "100%" : "400px",
        }}
      >
        <StyledHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => {
                  handleChatPanelOpen(false);
                  setRatingOpen(true);
                }}
              />
            }
          >
            <Flex align="center" wrap="nowrap">
              <ChatIcon
                style={{
                  width: 30,
                  height: 28,
                }}
              />
              <Text size={300} weight="semibold">
                {t("hbh.botHello.label")}
              </Text>
            </Flex>
          </DrawerHeaderTitle>
        </StyledHeader>

        <StyledBody>
          <Flex direction="column" width={"100%"} $gap={"20px"}>
            {messages.map((message) => {
              if (message.sender === "bot") {
                return (
                  <Flex
                    wrap="nowrap"
                    align="flex-start"
                    width={"100%"}
                    key={message.id}
                  >
                    <ChatIcon
                      style={{
                        width: 32,
                        height: 30,
                      }}
                    />
                    <BotMessage style={{ textAlign: "left" }}>
                      {message.message}
                    </BotMessage>
                  </Flex>
                );
              } else {
                return (
                  <Flex
                    wrap="nowrap"
                    align="flex-start"
                    width={"100%"}
                    key={message.id}
                  >
                    <UserMessage>{message.message}</UserMessage>
                    <Avatar
                      image={{
                        src: employeeData?.backgroundPicturePath,
                      }}
                      style={{
                        width: 30,
                        height: 30,
                        border: `1px solid ${theme.palette.themePrimary}`,
                      }}
                    />
                  </Flex>
                );
              }
            })}
          </Flex>
        </StyledBody>
        <StyledFooter>
          <NewMessage />
        </StyledFooter>
      </OverlayDrawer>
      {/* {ratingOpen && (
        <RatingDialog open={ratingOpen} onClose={handleCloseRatingDialog} />
      )} */}
    </>
  );
}

const MessageBox = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  min-height: 50px;
  background: ${({ theme }) => theme.palette.white};
  border: 1px solid #dedede;
  border-radius: 12px;
  padding: 10px;
  svg {
    width: 30px;
    height: 30px;
  }
`;

const BotMessage = styled(MessageBox)`
  text-align: left;
  border-top-left-radius: 0;
`;

const UserMessage = styled(MessageBox)`
  text-align: right;
  border-top-right-radius: 0;
`;

const StyledHeader = styled(DrawerHeader)<{ bgImage?: string }>`
  padding: 10px 24px;

  background: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.orangeLighter};
`;

const StyledBody = styled(DrawerBody)`
  padding: 10px;
  background: ${({}) =>
    `linear-gradient(rgba(255,255,255,.95), rgba(255,255,255,.95)), url("${chatLogo}")`};
  scrollbar-width: thin;
  background-color: ${({ theme }) => theme.palette.orangeLighter};
  background-size: calc(15px) calc(15px);
`;

const StyledFooter = styled(DrawerFooter)`
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
`;

// :before {
//   content: "";
//   position: absolute;
//   width: 200%;
//   height: 200%;
//   top: -50%;
//   left: -50%;
//   z-index: -1;
//   background: ${({ bgImage }) =>
//     `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url("${bgImage}")`};
//   transform: rotate(30deg);
//   background-repeat: repeat(auto-fill, 40px);
// }
