import { Button, Text } from "@fluentui/react-components";
import { SignOutRegular } from "@fluentui/react-icons";
import { PageContent } from "components/styled";
import { useGetMsalUserEmail } from "hooks/useGetMsalUserEmail";
import { useTranslation } from "react-i18next";
import { logout } from "service/auth";
import styled from "styled-components";

import HbhLogo from "../../assets/H&B_logo.png";

export function NoClientPage() {
  const { t } = useTranslation();
  const msalEmail = useGetMsalUserEmail();
  return (
    <PageContent>
      <Container>
        <img src={HbhLogo} alt="App Logo" width={50} height={50} />
        <Text weight="semibold" size={500}>
          {t("hbh.greeting.label", {
            name: msalEmail,
          })}
        </Text>
        <Text>{t("hbh.noClientInfo.label")}</Text>
        <Text>{t("hbh.contactSupport.label")}</Text>
        <Button
          onClick={() => logout()}
          appearance="transparent"
          icon={<SignOutRegular fontSize={18} />}
        >
          {t("header.SignOut")}
        </Button>
      </Container>
    </PageContent>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 75px);
`;
