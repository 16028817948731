import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IClient } from "library/types";
import { UseQueryResult, useQuery } from "react-query";
import api from "service/api";

const getUserClients = async () => {
  const response = await api.get(`/client`);

  return response.data;
};

export function useGetUserClients(enabled = true): UseQueryResult<IClient[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IClient[], Error>([QUERY_KEYS.userClients], getUserClients, {
    enabled: enabled,
    onError: (error: any) => {
      handleAddAxiosErrorNotification(error);
    },
  });
}
